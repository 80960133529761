<template>
  <div>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>

    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Add teaser Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-if="isVisibleSelectDepDialog" v-model="isVisibleSelectDepDialog" max-width="900px">
      <v-card>
        <v-card-title class="Primary">
          <span class="headline">Select Dep</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="5">
                <v-select v-if="dspDepsAndTeams.length > 1"
                          light
                          hide-details
                          dense
                          flat
                          :items="dspDepsAndTeams"
                          return-object
                          v-model="dspDep"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="isVisibleResultOfCreatedTaskForCallback" max-width="1500px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Created tasks</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 text-md-center">DSP Tasks</p>
                <v-data-table
                    :headers="headersOfCreatedDspTaskForCallback"
                    :items="resultOfCreatedDspTaskForCallback"
                    item-key="id"
                    hide-default-footer
                    dense
                >
                  <template v-slot:item.result="{ item }">
                    <v-icon v-if="item.result === true">mdi-check-bold</v-icon>
                    <v-icon v-else class="red--text">mdi-close</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h5 text-md-center">AB-test Tasks</p>
                <v-data-table
                    :headers="headersOfCreatedAbTaskForCallback"
                    :items="resultOfCreatedAbTaskForCallback"
                    item-key="id"
                    hide-default-footer
                    dense
                >
                  <template v-slot:item.result="{ item }">
                    <v-icon v-if="item.result === true">mdi-check-bold</v-icon>
                    <v-icon v-else class="red--text">mdi-close</v-icon>
                  </template>

                  <template v-slot:item.task="{ item }">
                    <span v-if="item.task === 1"><v-icon>mdi-numeric-1-circle-outline</v-icon></span>
                    <span v-else-if="item.task === 2"><v-icon>mdi-numeric-2-circle-outline</v-icon></span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="isVisibleCreateTaskFromExternalUpdates" v-model="isVisibleCreateTaskFromExternalUpdates"
              max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Create task</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="pb-0">
              <v-col class="text-center pb-0">Update</v-col>
            </v-row>
            <v-row>
              <v-data-table
                  v-model="selectedCreateTaskForExternalUpdate"
                  :headers="headersTeaserEternalUpdatesCreateTaskUpdates"
                  :items="selectedExternalUpdateItem.diffChips"
                  item-key="name"
                  hide-default-footer
                  dense
                  show-select
              >
                <template v-slot:item.current="{ item }">
                  <div v-if="item.name === 'Landing type'">
                    {{ item.current }} -> {{ item.new }}
                  </div>

                  <div v-if="item.name === 'Ad type'">
                    {{ item.current }} -> {{ item.new }}
                  </div>

                  <!--                  <div v-if="item.name === 'Tags'" :style="{'text-align': 'left'}">-->
                  <!--                    {{ item.current }}<br>{{ item.new }}-->
                  <!--                  </div>-->

                  <div
                      v-if="item.name === 'Title' || item.name === 'Title 2' || item.name === 'Tags' || item.name === 'Tags 2'">
                    <table>
                      <tr>
                        <td class="pl-4" :style="{'text-align': 'left'}">
                          Current
                        </td>
                        <td class="pl-4" :style="{'text-align': 'left'}">
                          {{ item.current }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-4" :style="{'text-align': 'left'}">
                          New
                        </td>
                        <td class="pl-4" :style="{'text-align': 'left'}">
                          {{ item.new }}
                        </td>
                      </tr>
                    </table>
                  </div>

                </template>
              </v-data-table>
            </v-row>

            <v-row class="pt-4 pb-0">
              <v-col class="text-center py-0"> Teaser and mgid_id for tasks</v-col>
            </v-row>
            <v-row class="pt-0">
              <v-data-table
                  v-model="selectedCreateTaskForExternalUpdateDstSelected"
                  :headers="headersTeaserEternalUpdatesCreateTaskDst"
                  :items="selectedCreateTaskForExternalUpdateDst"
                  hide-default-footer
                  dense
                  show-select
              >

                <template v-slot:item.id="{ item }">
                  <span v-if="item.id === id">this teaser ( {{ id }} )</span>
                  <span v-else>{{ item.id }}</span>
                </template>
                <template v-slot:item.mgid_id="{ item }">
                  <div v-if="item.mgid_id.toString() === selectedExternalUpdateItem.dataParsed.id.toString()">
                    <v-checkbox
                        v-if="!selectedCreateTaskForExternalUpdateDstSelected.some(i=> i.id === item.id)"
                        v-model="item.createtask" :label="item.mgid_id.toString()"
                        :disabled="selectedCreateTaskForExternalUpdateDstSelected.findIndex(i => i.id === item.id) > -1"
                    ></v-checkbox>
                    <div v-else> {{ item.mgid_id.toString() }}</div>
                  </div>
                </template>

                <template v-slot:item.mgid_id_2="{ item }">
                  <div
                      v-if="item.abtest_on === 1 && item.mgid_id_2.toString() === selectedExternalUpdateItem.dataParsed.id.toString()">
                    <v-checkbox
                        v-if="!selectedCreateTaskForExternalUpdateDstSelected.some(i=> i.id === item.id)"
                        v-model="item.createtask_2" :label="item.mgid_id_2.toString()"
                        :disabled="selectedCreateTaskForExternalUpdateDstSelected.findIndex(i => i.id === item.id) > -1"
                    ></v-checkbox>
                    <div v-else> {{ item.mgid_id_2.toString() }}</div>
                  </div>
                </template>

                <!--                  <div v-if="item.name === 'Title'">-->
                <!--                    <table>-->
                <!--                      <tr>-->
                <!--                        <td class="pl-4" :style="{'text-align': 'left'}">-->
                <!--                          Current-->
                <!--                        </td>-->
                <!--                        <td class="pl-4" :style="{'text-align': 'left'}">-->
                <!--                          {{ item.current }}-->
                <!--                        </td>-->
                <!--                      </tr>-->
                <!--                      <tr>-->
                <!--                        <td class="pl-4" :style="{'text-align': 'left'}">-->
                <!--                          New-->
                <!--                        </td>-->
                <!--                        <td class="pl-4" :style="{'text-align': 'left'}">-->
                <!--                          {{ item.new }}-->
                <!--                        </td>-->
                <!--                      </tr>-->
                <!--                    </table>-->
                <!--                  </div>-->

                <!--                </template>-->
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isVisibleCreateTaskFromExternalUpdates = false">Cancel</v-btn>
          <v-btn color="primary" @click="onCreateTaskFromExternalUpdates"
                 :disabled="selectedCreateTaskForExternalUpdate.length === 0 || !(selectedCreateTaskForExternalUpdateDstSelected.length > 0 || selectedCreateTaskForExternalUpdateDst.some((i) => i.createtask === true || i.createtask_2 === true))">
            Create Tasks
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-if="isVisibleViewExternalUpdateItem" v-model="isVisibleViewExternalUpdateItem" max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Origin CallBack data from mgid</span>
        </v-card-title>
        <v-card-text :style="{overflow: 'auto'}">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <pre>{{ selectedExternalUpdateItemDataPretty }}</pre>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="confirmDeleteTask" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Delete task {{ taskForDelete.operation_id }}?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteTask = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteTask">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!--      <v-card-text>-->
        <!--        <v-container>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" sm="12" md="12">-->
        <!--              <p class="text-h6">{{ errorMessage }}</p>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-container>-->
        <!--      </v-card-text>-->
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Delete teaser from moderation?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteFromModeration">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!--      <v-card-text>-->
        <!--        <v-container>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" sm="12" md="12">-->
        <!--              <p class="text-h6">{{ errorMessage }}</p>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-container>-->
        <!--      </v-card-text>-->
      </v-card>
    </v-dialog>


    <v-dialog v-model="confirmDeleteTeaser" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Delete teaser id: {{ id }}?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteTeaser = false">Cancel</v-btn>
          <v-btn color="error" @click="teaserSetFolderDeleted()">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="chooseImageDialog" fullscreen>
      <!--      <v-container fluid>-->
      <v-card fluid>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="3">
              <v-card flat>
                <v-card-title class="align-center">
                  <span class="headline justify-center">Tags</span>
                </v-card-title>
                <v-card-text>
                  <v-combobox
                      v-model="selectedTags"
                      :items="imgTags"
                      chips
                      clearable
                      label="Select tags.."
                      multiple
                      solo
                      @click:clear="clearSelectedTags"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-card-text>
                <v-chip v-for="item in imgTagsWithRating" :key="item.tag" class="mx-1 my-1"
                        :disabled="item.selected" @click="selectTagInCloud(item)">
                  <strong>{{ item.tag }}</strong>
                </v-chip>

              </v-card>
            </v-col>
            <v-col cols="12" sm="9">
              <v-card flat>
                <v-card-title class="pt-0 mt-0">
                  <!--                  <span class="headline">Choose Image</span>-->
                  <span class="headline">Choose</span>
                  <v-btn-toggle
                      v-model="imageType"
                      @change="switchImageType()"
                      tile
                      color="primary"
                      group
                  >
                    <v-btn value="native">
                      Image
                    </v-btn>

                    <v-btn value="video">
                      Video
                    </v-btn>

                  </v-btn-toggle>
                </v-card-title>
                <v-card-title class="pt-0 mt-0">
                  <v-row>
                    <v-col cols="12" sm="1">
                      <v-btn color="primary" class="pt-0 mt-1" @click="dialogAddNewImage = true">
                        <v-icon dark>mdi-plus</v-icon>
                        Add
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-pagination
                          v-model="pageImages"
                          :length="pageImagesTotal"
                          :total-visible="10"
                      ></v-pagination>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select
                          class="pt-1 mr-0"
                          v-model="imagesPerPage"
                          :items="imagesPerPageItems"
                          hide-details
                          dense
                          solo
                      ></v-select>
                    </v-col>
                  </v-row>


                </v-card-title>
                <v-card-text>
                  <v-row v-if="imageType === 'video'">
                    <v-col
                        v-for="img in images"
                        :key="img.id"
                        class="d-flex child-flex"
                        cols="3"
                    >


                      <v-hover v-slot="{ hover }">
                        <div @click="onClickImage(img)"
                             :class="{ 'on-hover': hover }"
                             class="text-right">
                          <v-btn v-if="hover" absolute small fab @click.stop="openImageTagEditor(img)">
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>

                          <!--                          <v-icon color="red" class="pr-1 pt-1"-->
                          <!--                                  absolute-->
                          <!--                                  fab-->
                          <!--                                  v-if="!hover && img.tag1 === '' && img.tag2 === '' && img.tag3 === ''">-->
                          <!--                            mdi-alert-circle-outline-->
                          <!--                          </v-icon>-->
                          <video-player
                              :options="img.videoOptions"

                              :style="imageStyle(img)"
                          >
                          </video-player>
                        </div>
                      </v-hover>

                      <!--                      <v-hover v-slot="{ hover }">-->
                      <!--                        <video-player-->
                      <!--                            :options="img.videoOptions"-->
                      <!--                            @click="onClickImage(img)"-->
                      <!--                            :style="imageStyle(img)"-->
                      <!--                        >-->
                      <!--                          <v-btn small fab v-if="hover" @click.stop="openImageTagEditor(img)">-->
                      <!--                            <v-icon>mdi-pencil-outline</v-icon>-->
                      <!--                          </v-btn>-->

                      <!--                          <v-icon color="red" class="pr-1 pt-1"-->
                      <!--                                  v-if="!hover && img.tag1 === '' && img.tag2 === '' && img.tag3 === ''">-->
                      <!--                            mdi-alert-circle-outline-->
                      <!--                          </v-icon>-->

                      <!--                        </video-player>-->
                      <!--                        -->
                      <!--                      </v-hover>-->
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col
                        v-for="img in images"
                        :key="img.id"
                        class="d-flex child-flex"
                        cols="3"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-img
                            contain
                            :style="imageStyle(img)"
                            :src="getImageUrl(img.url)"
                            :lazy-src="img.url"
                            :elevation="hover ? 0: 12"
                            :class="{ 'on-hover': hover }"
                            class="text-right"
                            @click="onClickImage(img)"
                        >
                          <v-btn small fab @click.stop="openImageTagEditor(img)">
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>

                          <v-icon color="red" class="pr-1 pt-1"
                                  v-if="!hover && img.tag1 === '' && img.tag2 === '' && img.tag3 === ''">
                            mdi-alert-circle-outline
                          </v-icon>

                          <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                              <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!--          </v-row>-->

                <!--        </v-card-text>-->
                <v-card-actions>
                  <v-card-title class="pt-0 mt-0">
                    <v-row>

                      <v-col cols="12" sm="9">
                        <v-pagination
                            v-model="pageImages"
                            :length="pageImagesTotal"
                            :total-visible="10"
                        ></v-pagination>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-select
                            class="pt-1 mr-0"
                            v-model="imagesPerPage"
                            :items="imagesPerPageItems"
                            hide-details
                            dense
                            solo
                        ></v-select>
                      </v-col>
                    </v-row>


                  </v-card-title>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="onCancelImageDialog">Cancel</v-btn>
                  <v-btn color="primary" :disabled="isDisabledImageSelectBtn" @click="onSelectImage">
                    Select
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>


    <DialogAddNewImage v-if="dialogAddNewImage && (imageType === 'native' || imageType === 'banner')"
                       v-model="dialogAddNewImage" :tags="imgTags"
                       :selectedImage="selectedImage"
                       @new-image="onNewImage"
    ></DialogAddNewImage>

    <DialogAddNewVideo v-if="dialogAddNewImage && imageType === 'video' " v-model="dialogAddNewImage" :tags="imgTags"
                       :selectedImage="selectedImage"
                       @new-image="onNewImage"
    ></DialogAddNewVideo>

    <v-dialog v-if="dialogEditImageTags" v-model="dialogEditImageTags" max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Update Image Tags</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="selectedImage.url.toString().match(/^https?:/)">
              <v-col>
                <v-img
                    contain
                    :src="getImageUrl(selectedImage.url)"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                1x1
                <v-img
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    contain
                    :aspect-ratio="1/1"
                    :src="getImageUrl(selectedImage.url, '1x1')"
                />
              </v-col>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                2x1
                <v-img
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    contain
                    :aspect-ratio="2/1"
                    :src="getImageUrl(selectedImage.url , '2x1')"
                />
              </v-col>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                3x2
                <v-img
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    contain
                    :aspect-ratio="3/2"
                    :src="getImageUrl(selectedImage.url , '3x2')"
                />
              </v-col>
              <v-col cols="12" sm="5" class="text-center" align-self="center">
                4x3
                <v-img
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    contain
                    :aspect-ratio="4/3"
                    :src="getImageUrl(selectedImage.url, '4x3')"
                />
              </v-col>
              <v-col cols="12" sm="6" class="text-center" align-self="center">
                16x9
                <v-img
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    contain
                    :aspect-ratio="16/9"
                    :src="getImageUrl(selectedImage.url , '16x9')"
                />
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag1" :items="imgTags" clearable
                            @click:clear="clearTag('tag1')"></v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag2" :items="imgTags" clearable
                            @click:clear="clearTag('tag2')"></v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag3" :items="imgTags" clearable
                            @click:clear="clearTag('tag3')"></v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditImageTags = false">Cancel</v-btn>
          <v-btn color="primary" :disabled="isDisabledBtnUpdateTags()"
                 @click="onUpdateImageTags">
            <v-icon>mdi-content-save</v-icon>
            Update
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogEditVideoTags" v-model="dialogEditVideoTags" max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Update Image Tags</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="selectedImage.url.toString().match(/^https?:/)">
              <v-col>
                <video-player :options="selectedImage.videoOptions"></video-player>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                1x1
                <video-player
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    :options="selectedImage.videoOptions_1x1" :aspect-ratio="1/1"></video-player>
                <!--                <v-img-->
                <!--                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"-->
                <!--                    contain-->
                <!--                    :aspect-ratio="1/1"-->
                <!--                    :src="getImageUrl(selectedImage.url, '1x1')"-->
                <!--                />-->
              </v-col>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                2x1
                <!--                <v-img-->
                <!--                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"-->
                <!--                    contain-->
                <!--                    :aspect-ratio="2/1"-->
                <!--                    :src="getImageUrl(selectedImage.url , '2x1')"-->
                <!--                />-->

                <video-player
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    :options="selectedImage.videoOptions_2x1" :aspect-ratio="2/1"></video-player>
              </v-col>
              <v-col cols="12" sm="4" class="text-center" align-self="center">
                3x2
                <video-player
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    :options="selectedImage.videoOptions_3x2" :aspect-ratio="3/2"></video-player>
                <!--                <v-img-->
                <!--                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"-->
                <!--                    contain-->
                <!--                    :aspect-ratio="3/2"-->
                <!--                    :src="getImageUrl(selectedImage.url , '3x2')"-->
                <!--                />-->
              </v-col>
              <v-col cols="12" sm="5" class="text-center" align-self="center">
                4x3
                <video-player
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    :options="selectedImage.videoOptions_4x3" :aspect-ratio="4/3"></video-player>
                <!--                <v-img-->
                <!--                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"-->
                <!--                    contain-->
                <!--                    :aspect-ratio="4/3"-->
                <!--                    :src="getImageUrl(selectedImage.url, '4x3')"-->
                <!--                />-->
              </v-col>
              <v-col cols="12" sm="6" class="text-center" align-self="center">
                16x9
                <video-player
                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"
                    :options="selectedImage.videoOptions_16x9" :aspect-ratio="16/9"></video-player>
                <!--                <v-img-->
                <!--                    :style="{border: 'thick solid #bdbdbd', 'border-width': '1px'}"-->
                <!--                    contain-->
                <!--                    :aspect-ratio="16/9"-->
                <!--                    :src="getImageUrl(selectedImage.url , '16x9')"-->
                <!--                />-->
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag1" :items="imgTags" clearable
                            @click:clear="clearTag('tag1')"></v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag2" :items="imgTags" clearable
                            @click:clear="clearTag('tag2')"></v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-combobox label="Tag" v-model="tag3" :items="imgTags" clearable
                            @click:clear="clearTag('tag3')"></v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditVideoTags = false">Cancel</v-btn>
          <v-btn color="primary" :disabled="isDisabledBtnUpdateTags()"
                 @click="onUpdateImageTags">
            <v-icon>mdi-content-save</v-icon>
            Update
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog"
        v-if="dialog"
        :persistent="!ro"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind="attrs"></slot>
      </template>
      <v-card flat>
        <v-card-title class="headline" v-if="copy">
          Copy teaser
        </v-card-title>
        <v-card-title class="headline" v-else-if="!copy && ro">
          View teaser: {{ this_id }}
          <v-toolbar dense flat v-if="tabs === 'tab-teaser'">

            <v-tooltip bottom v-if="thisOn_Off === 0">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on"
                         @click="thisOn_Off = 1">
                    <v-icon v-if="hover" color="green">mdi-play-circle</v-icon>
                    <v-icon v-if="!hover" color="red">mdi-pause</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>Start teaser</span>
            </v-tooltip>

            <v-tooltip bottom v-if="thisOn_Off === 1">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on"
                         @click="thisOn_Off = 0">
                    <v-icon v-if="hover" color="red">mdi-pause</v-icon>
                    <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>Stop teaser</span>
            </v-tooltip>

            <v-tooltip bottom v-if="on_off === 0 && thisScheduleEnabled === 0 && thisFolder === 'active'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :loading="loadingSetFolder"
                    @click.stop="teaserSetFolderArchive">
                  <v-icon color="yellow">mdi-archive-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>to Archive</span>
            </v-tooltip>

            <v-tooltip bottom v-if="on_off === 0 && thisScheduleEnabled === 0 && thisFolder === 'archive'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="teaserSetFolderActive()">
                  <v-icon color="green">mdi-archive-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>to Active</span>
            </v-tooltip>

            <v-tooltip bottom
                       v-if="on_off === 0 && thisScheduleEnabled === 0 && (thisFolder === 'active' || thisFolder === 'archive')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="confirmDeleteTeaser = true">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete teaser</span>
            </v-tooltip>


            <!--            <div class="sm1" v-if="thisScheduleEnabled">-->
            <!--              <v-select-->
            <!--                  label="Folder"-->
            <!--                  dense-->
            <!--                  v-model="folderThis"-->
            <!--                  :items="folderItems"-->
            <!--                  required-->
            <!--                  hide-details-->

            <!--              ></v-select>-->
            <!--            </div>-->


          </v-toolbar>

        </v-card-title>
        <v-card-title class="headline" v-else>
          Add teaser for : <b class="pl-1">{{ teams.text }}</b>
        </v-card-title>

        <v-card-text>

          <v-tabs right height="22" v-model="tabs">

            <v-tab href="#tab-teaser">
              <v-icon>mdi-information-variant</v-icon>
            </v-tab>

            <v-tab href="#tab-target">
              <v-icon>mdi-bullseye-arrow</v-icon>
            </v-tab>

            <v-tab href="#tab-tasks">
              <v-icon v-if="waitTask === 0">mdi-calendar-check-outline</v-icon>
              <v-icon v-else color="error">mdi-calendar-check-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-ab-tasks">
              <v-icon v-if="waitAbTask === 0">mdi-ab-testing</v-icon>
              <v-icon v-else color="error">mdi-ab-testing</v-icon>
            </v-tab>

            <v-tab href="#tab-eternal-updates" v-if="$route.params.section === 'Mgid'">
              <v-icon>mdi-database-arrow-down-outline</v-icon>
            </v-tab>


            <v-tab href="#tab-schedule">
              <v-icon v-if="thisScheduleEnabled">mdi-alarm</v-icon>
              <v-icon v-else>mdi-alarm-off</v-icon>
            </v-tab>


            <v-tab href="#tab-auction-stat" v-if="showDspAuctionStat">
              <v-icon>mdi-gavel</v-icon>
            </v-tab>

            <v-tab href="#tab-cLimit-achieved" v-if="!onModeration && achievedClimitCount>0">
              <v-icon>mdi-format-vertical-align-top</v-icon>
            </v-tab>

          </v-tabs>


          <v-tabs-items v-model="tabs" class="pt-4">

            <v-tab-item value="tab-teaser">
              <v-form ref="form" v-model="valid">
                <v-container fluid>
                  <!--                <v-container class="pt-0">-->
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                          label="Country"
                          dense
                          v-model="thisCountry"
                          :items="countries"
                          :required="createNew || onModeration"
                          :rules="[countryValidate]"
                          :readonly="ro || isMgidModerate"
                          auto-select-first
                      >
                        <template v-slot:prepend>
                          <flag :iso="thisCountry"/>
                        </template>

                        <template v-slot:item="{ item }">
                          <flag :iso="item.value"/>
                          {{ item.text }}
                        </template>

                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                          label="Offer"
                          dense
                          v-model="thisOffer"
                          :rules="[offerValidate]"
                          :readonly="ro || isMgidModerate"
                          :required="createNew || onModeration"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" v-if="createNew">
                      <v-checkbox v-show="isValidFullOfferName"
                                  v-model="isValidFullOfferName"
                                  :rules="[() => isValidFullOfferName]" class="pa-0 ma-0 pt-5 pl-6"
                                  hide-details></v-checkbox>
                      <v-tooltip bottom v-if="!isValidFullOfferName">
                        <template v-slot:activator="{ on,attrs }">
                          <v-btn v-on="on" v-bind="attrs" icon class="mt-3" color="primary"
                                 :disabled="offerValidate(thisOffer) !== true || countryValidate(thisCountry) !== true"
                                 @click="validateFullOfferName(thisOffer)">
                            <v-icon>mdi-send-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Validate offer name</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                          label="Lang"
                          dense
                          @change="onChangeThisLang"
                          v-model="thisLang"
                          :items="languagesISO"
                          :readonly="isRoLang() || isMgidModerate"
                          :rules="[validateLang]"
                          :required="createNew || onModeration || lang === ''"
                          :append-icon="enableThisLangSaveIcon()"
                          @click:append="saveLang"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-autocomplete
                          label="Lang filter"
                          dense
                          v-model="thisLangFilter"
                          :items="languagesISO"
                          :readonly="ro"
                          :required="createNew || onModeration"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!--                    <v-col cols="12" sm="8" v-if="isBlanc">-->
                  <!--                    </v-col>-->
                  <v-row>
                    <v-col cols="12" sm="8" v-if="isVisibleIABCategory">
                      <v-row>
                        <v-col cols="12" sm="3">
                          <v-autocomplete
                              label="IAB category"
                              dense
                              v-model="thisCatId"
                              :items="internalIabCategories"
                              :rules="[iabCatValidate]"
                              :readonly="ro || isMgidModerate"
                              return-object
                              :required="createNew || onModeration"
                              item-text="textAndId"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="isVisibleAdultType">
                      <v-row>
                        <v-col cols="12" sm="3">
                          <v-select
                              label="Adult ?"
                              dense
                              v-model="thisAdult"
                              :items="adultItems"
                              :readonly="ro"
                              required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-select
                              label="Format"
                              dense
                              v-model="thisAd_format"
                              :items="ad_formatItems"
                              :readonly="ro"
                              :required="createNew || onModeration"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" v-if="isVisibleAdTypeAndLandTypeAndIabCat">
                      <v-row>
                        <!--                        <v-col cols="12" sm="1" class="mt-2 pb-0">-->
                        <!--                          <v-icon>mdi-shape-outline</v-icon>-->
                        <!--                        </v-col>-->
                        <v-col cols="12" sm="2" v-if="isVisibleAdTypeAndLandType">
                          <v-select
                              label="Ad type"
                              dense
                              v-model="thisAd_type"
                              :items="landingTypes"
                              :rules="[adTypeValidate]"
                              :readonly="isRoAdType() || isMgidModerate"
                              required
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi-shape-outline</v-icon>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="isVisibleLandType">
                          <v-select
                              label="Landing type"
                              dense
                              v-model="thisLanding_type"
                              :items="landingTypes"
                              :rules="[landingTypeValidate]"
                              :readonly="ro && !viewTeaser"
                              required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-autocomplete
                              label="IAB category"
                              dense
                              v-model="thisCatId"
                              :items="internalIabCategories"
                              :rules="[iabCatValidate]"
                              :readonly="ro || isMgidModerate"
                              return-object
                              :required="createNew || onModeration"
                              item-text="textAndId"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-autocomplete
                              label="User Agent filter"
                              dense
                              v-model="thisUserAgent"
                              :items="userAgentList"
                              :readonly="ro && !viewTeaser"
                              clearable
                              multiple
                              return-object
                              :required="createNew || onModeration"
                              item-value="group_id"
                              item-text="name"
                              @click="onClickUserAgentInput"
                              @blur="onClickUserAgentBlur"
                          >
                            <template v-slot:item="{ item }">
                              {{ item.name }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="12" v-if="!isVisibleAdTypeAndLandTypeAndIabCat">
                      <v-autocomplete
                          label="User Agent filter"
                          dense
                          v-model="thisUserAgent"
                          :items="userAgentList"
                          :readonly="ro && !viewTeaser"
                          clearable
                          multiple
                          return-object
                          :required="createNew || onModeration"
                          item-value="group_id"
                          item-text="name"
                          @click="onClickUserAgentInput"
                          @blur="onClickUserAgentBlur"
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisPayout"
                          label="Payout"
                          type="string"
                          prepend-icon="mdi-cash"
                          :rules="[payoutValidate]"
                          :readonly="ro && !viewTeaser"
                          required
                      >
                        <template v-slot:append-outer>
                          $
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisApprove"
                          label="Approve"
                          type="string"
                          prepend-icon="mdi-check-decagram"
                          :rules="[approveValidate]"
                          :readonly="ro && !viewTeaser"
                          required
                      >
                        <template v-slot:append-outer>
                          %
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select
                          :items="modeItems"
                          label="Mode"
                          v-model="thisMode"
                          prepend-icon="mdi-alpha-m-circle-outline"
                          :readonly="ro && !viewTeaser"
                          :value="0"
                          required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-select
                          :items="roiSourcesItems"
                          label="ROI For sources"
                          v-model="thisRoiForSources"
                          prepend-icon="mdi-trending-up"
                          :readonly="ro && !viewTeaser"
                          required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-select
                          :items="roiUsersItems"
                          label="ROI For users"
                          v-model="thisRoiForUsers"

                          :readonly="ro && !viewTeaser"
                          required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="2" v-if="isVisibleTurbo">
                      <v-select
                          :items="thisTurboItems"
                          label="Turbo"
                          v-model="thisTurbo"
                          prepend-icon="mdi-car-turbocharger"
                          :readonly="ro && !viewTeaser"
                          :value="0"
                          required
                      ></v-select>
                    </v-col>


                    <v-col cols="12" sm="4" class="pl-0 pt-4">
                      <v-row>
                        <v-col cols="12" sm="1" class="mr-0 pr-0">
                          <v-checkbox color="red" class="mr-0 pr-0" v-model="thisNo_leads"
                                      :readonly="ro && !viewTeaser">
                            <template v-slot:prepend>
                              <v-icon class="pl-3 pt-0">mdi-alert-circle</v-icon>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-0 pl-0 pt-8 text-center">
                          <span class="text-caption pl-3 text-center">Only 'Action'</span>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-select
                              :items="retarget_modeItems"
                              label="Retarget mode"
                              v-model="thisRetarget_mode"
                              :readonly="ro && !viewTeaser"
                              :required="createNew || onModeration"
                              :value="0"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi-account-search-outline</v-icon>
                            </template>
                            <template v-slot:append>
                              <v-menu open-on-hover>
                                <template v-slot:activator="{ on }">
                                  <v-icon small v-on="on">mdi-information-variant</v-icon>
                                </template>
                                <!-- v-menu content -->
                                <v-card>
                                  <v-card>
                                    <!--                                    <v-card-title class="primary">-->
                                    <!--                                      <span class="headline">Add teaser Error</span>-->
                                    <!--                                    </v-card-title>-->
                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12" class="text-h7">
                                            <p><b>0</b> - ретаргетинг выключен</p>
                                            <p><b>1</b> - ретаргетинг включен на всех разрешенных, но
                                              выключенных источниках. 0-21 режим офера - работает на всех пользователей,
                                              у которых РОИ > 0%. 21-100 режим офера - работает на всех пользователей, у
                                              которых РОИ > [режим офера]%. Только ретаргетинг, без прямой закупки</p>
                                            <p><b>2</b> - покупка разрешена только на ретаргетируемых
                                              пользователей и только на включенных источниках</p>
                                            <p><b>3</b> - то же самое что и 1 mode, только ретаргетинг не
                                              смотрит на режим офера, работает на всех пользователей, у которых РОИ
                                              >0%</p>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
                                  </v-card>
                                </v-card>
                              </v-menu>
                              <!--                              <v-hover v-slot:default="{ hover, on}">-->
                              <!--                                <div>-->
                              <!--                                  <v-icon small>mdi-content-copy</v-icon>-->
                              <!--&lt;!&ndash;                                  <v-menu :style="{ visibility: hover ? 'visible': 'hidden' }">&ndash;&gt;-->
                              <!--&lt;!&ndash;                                    mdi-content-copy&ndash;&gt;-->
                              <!--&lt;!&ndash;                                  </v-menu>&ndash;&gt;-->

                              <!--                                  <v-menu v-on="on" :value="hover" max-width="900px" >-->
                              <!--                                    <v-card>-->
                              <!--                                      <v-card-title class="red">-->
                              <!--                                        <span class="headline">Add teaser Error</span>-->
                              <!--                                      </v-card-title>-->
                              <!--                                      <v-card-text>-->
                              <!--                                        <v-container>-->
                              <!--                                          <v-row>-->
                              <!--                                            <v-col cols="12" sm="12" md="12">-->
                              <!--                                              <p class="text-h6">{{ errorMessage }}</p>-->
                              <!--                                            </v-col>-->
                              <!--                                          </v-row>-->
                              <!--                                        </v-container>-->
                              <!--                                      </v-card-text>-->
                              <!--                                    </v-card>-->
                              <!--                                  </v-menu>-->
                              <!--                                </div>-->
                              <!--                              </v-hover>-->
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--                    <v-col cols="12" sm="2" v-if="isVisibleTurbo">-->
                    <!--                    </v-col>-->
                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisClimit"
                          label="C Limit"
                          type="string"
                          :rules="[cLimitValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-format-vertical-align-top</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisClimitR"
                          label="C Limit R"
                          type="string"
                          :rules="[cLimitRValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                        <template v-slot:prepend>
                          <v-badge
                              color="grey lighten-1"
                              offset-x="10"
                              offset-y="10"
                              content="R"
                          >
                            <v-icon>mdi-format-vertical-align-top</v-icon>
                            <span></span>
                          </v-badge>

                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisStartMcpm"
                          label="Start MCPM"
                          type="string"
                          :rules="[startMcpmValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-chevron-right-circle-outline</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisLeadLimitDaily"
                          label="Daily limit, Leads"
                          type="string"
                          :rules="[leadLimitDailyValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-clock-end</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                          v-model="thisSpendLimitDaily"
                          label="Daily limit, $"
                          type="string"
                          :rules="[spendLimitDailyValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-cash-lock</v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                          v-model="thisButton_text"
                          label="Button text"
                          type="string"
                          :rules="[buttonTextValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select
                          v-model="thisAggression"
                          label="Aggression"
                          type="string"
                          :rules="[aggressionValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                          :items="aggressionItems"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-emoticon-devil</v-icon>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-checkbox
                          v-model="thisManual_opti"
                          label="Manual Opti"
                          type="string"
                          :disabled="!isAllowChangeManual_opti"
                          :readonly="ro && !viewTeaser"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-car-shift-pattern</v-icon>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row justify="center" align="center" class="pt-2 pb-2">
                    <v-tooltip bottom v-if="!thisDeleteFirstTeaser && thisAbtest_on">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="!( thisAbtest_on && waitAbTaskDeleteFirstTeaser === 0)"
                               icon
                               v-on="on" v-bind="attrs"
                               @click="thisDeleteFirstTeaser = true">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete teaser</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="thisDeleteFirstTeaser">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs"
                               @click="thisDeleteFirstTeaser = false">
                          <v-icon color="red">mdi-delete-restore</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancel delete</span>
                    </v-tooltip>

                  </v-row>

                  <v-row class="pb-0" v-if="thisDeleteFirstTeaser === false">
                    <v-col cols="12" sm="4">
                      <v-row class="pt-8">
                        <v-col cols="12">
                          <!--                          <v-hover v-if="videoOptions" v-slot="{ hover: hoverImg }" class="align-content-sm-center">-->
                          <!--                            <div :class="{ 'on-hover': hoverImg }"-->
                          <!--                                 @click.stop="clickOnImageTeaser(true, item)"-->
                          <!--                                 :style="{ 'cursor': hoverImg ? 'pointer': 'default' }"-->
                          <!--                                 :elevation="hoverImg ? 0 : 12">-->
                          <!--                            </div>-->
                          <!--                          </v-hover>-->

                          <div v-if="thisVideoOptions" @click="openChooseImageDialog(true)">
                            <video-player :options="thisVideoOptions"></video-player>
                          </div>

                          <v-img v-else contain
                                 :src="getImageUrl(thisImage)"
                                 @click="openChooseImageDialog(true)"
                                 class="text-right"
                          >
                            <v-tooltip bottom v-if="thisImage.toString().match(/^\//)">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs">mdi-aspect-ratio
                                </v-icon>
                              </template>
                              <span>Image with aspect ratio</span>
                            </v-tooltip>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="8">

                      <v-text-field
                          v-bind:value="thisLink"
                          label="Click-URL"
                          type="string"
                          :rules="[linkValidate]"
                          :readonly="ro && !viewTeaser"
                          :required="createNew || onModeration"
                          @input="setLink($event)"
                      >
                        <!--                  <template v-slot:append-outer>-->
                        <template v-slot:append-outer>
                          <v-btn icon :href="thisLink" target="_blank"
                                 :disabled="thisLink.length=== 0 || thisLink=== null">
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>
                          <v-btn icon :href="getPeerclickCampaignLink(thisLink)" target="_blank"
                                 :disabled="thisLink.length=== 0 || thisLink=== null">
                            <v-icon>mdi-alpha-c-circle-outline</v-icon>
                          </v-btn>
                          <div v-for="lnk in getPeerclickOffersLink(thisLink)" :key="lnk">
                            <v-btn icon :href="lnk" target="_blank">
                              <v-icon>mdi-alpha-o-circle-outline</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </v-text-field>

                      <v-text-field
                          v-if="isVisibleLandPage"
                          v-bind:value="thisLp"
                          label="Land page Domain"
                          type="string"
                          :rules="[domainValidate]"
                          :readonly="(ro && !viewTeaser) || isMgidModerate"
                          :required="createNew || onModeration"
                          @input="setLp($event)"
                      >
                      </v-text-field>

                      <v-row v-if="$route.params.section === 'Mgid'">
                        <v-col cols="12" sm="9">
                          <v-combobox multiple label="Tags"
                                      :readonly="(ro && !viewTeaser) || isMgidModerate"
                                      v-model="thisTags" :items="tagsList"
                                      :delimiters="[',',' ']"
                                      chips
                                      :disabled="thisIsDisabledTags"
                                      :rules="[validateTags]"
                                      @change="delimit"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-row>
                            <v-col cols="12" sm="4" class="mr-0 pr-0">
                              <v-checkbox color="red" v-model="thisIsDisabledTags"
                                          :readonly="(ro && !viewTeaser) || isMgidModerate"
                                          class="pl-2"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="8" class="ml-0 pl-0 pt-8 text-center">
                              <span class="text-caption pl-2 text-center">Disable Tags</span>
                            </v-col>
                          </v-row>

                        </v-col>
                      </v-row>


                      <v-text-field
                          v-model="thisImage"
                          label="Image"
                          type="string"
                          :required="createNew || onModeration"
                          :rules="[imageValidate]"
                          :readonly="(ro && !viewTeaser) || isMgidModerate"
                          prepend-icon="mdi-magnify"
                          @click:prepend="openChooseImageDialog(true)"
                          @change="onChangeImageUrl(thisImage, true)"
                      >
                      </v-text-field>

                      <v-row>
                        <v-col cols="12" :sm="teams.length>1? 2 : 3"
                               v-if="isVisibleMgidIdInputs">
                        </v-col>
                        <v-col cols="12" :sm="teams.length>1? 2 : 3"
                               v-if="!isVisibleMgidIdInputs">
                          <v-text-field
                              label="MGID teaser ID"
                              type="string"
                              v-model="thisMgid_id"
                              :rules="[mgidIdValidate]"
                              :required="createNew || onModeration"
                              :readonly="(createNew ? ro && !viewTeaser : ro && !viewTeaser || allowSendModerateAgain) || isMgidModerate"
                          >
                          </v-text-field>
                        </v-col>
                        <!--                  <v-col cols="12" :sm="teams.length>1? 5 : 5">-->
                        <v-col cols="12" :sm="teams.value.length>1? 4 : 5">
                          <v-autocomplete label="PP"
                                          v-model="thisPp"
                                          :items="pps"
                                          :required="createNew || onModeration"
                                          :rules="[ppValidate]"
                                          :readonly="ro && !viewTeaser"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" v-if="teams.value.length>1">
                          <v-autocomplete label="Teams"
                                          return-object
                                          v-model="thisTeam"
                                          :items="teams.value"
                                          :readonly="ro && !viewTeaser"
                                          :rules="[teamValidate]"
                                          :required="createNew || onModeration"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" :sm="teams.value.length>1? 2 : 4">
                          <v-autocomplete label="Owner"
                                          v-model="thisOwner"
                                          :items="thisTeam.value"
                                          :disabled="!thisTeam.value || thisTeam.value.length === 0"
                                          :readonly="ro && !viewTeaser"
                                          :rules="[ownerValidate]"
                                          :required="createNew || onModeration"
                          >

                          </v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row v-if="isVisibleAdultTitle">
                        <v-col cols="12">
                          <v-text-field
                              v-bind:value="thisTitle"
                              @input="setTitle($event)"
                              label="Title"
                              type="string"
                              :rules="[titleValidate]"
                              :readonly="(ro && !viewTeaser) || isMgidModerate"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="thisBrand"
                              label="Brand"
                              type="string"
                              :rules="[brandValidate]"
                              :readonly="ro && !viewTeaser"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="py-3 pb-5">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row justify="center" align="center" class="pt-2 pb-2">
                    <v-switch v-model="thisAbtest_on" label="Enable"
                              :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser"
                              hide-details></v-switch>
                    <v-icon class="pl-4 pt-4">mdi-ab-testing</v-icon>
                    <div class="pt-4 pl-5 text-subtitle-1">test</div>
                  </v-row>
                  <v-row v-if="thisAbtest_on" class="pt-9">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row v-if="thisAbtest_on" class="pt-0">
                    <v-col cols="12" sm="4" class="pt-6">
                      <v-row>
                        <v-col cols="12">
                          <div v-if="thisVideoOptions_2" @click="openChooseImageDialog(false)">
                            <video-player :options="thisVideoOptions_2"></video-player>
                          </div>
                          <v-img v-else :src="getImageUrl(thisImage_2)" contain
                                 @click="openChooseImageDialog(false)"
                                 class="text-right">
                            <v-tooltip bottom v-if="thisImage_2.toString().match(/^\//)">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs">mdi-aspect-ratio
                                </v-icon>
                              </template>
                              <span>Image with aspect ratio</span>
                            </v-tooltip>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-row>

                        <v-col cols="12">
                          <v-text-field
                              v-bind:value="thisLink_2"
                              label="Click-URL"
                              type="string"
                              :rules="[linkValidate]"
                              :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser"
                              required
                              @input="setLink2($event)"
                          >
                            <template v-slot:append-outer>
                              <v-btn icon :href="thisLink_2" target="_blank"
                                     :disabled="thisLink_2.length=== 0 || thisLink_2=== null">
                                <v-icon>mdi-open-in-new</v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-text-field
                            v-if="isVisibleLandPage"
                            v-bind:value="thisLp_2"
                            label="Land page Domain"
                            type="string"
                            :rules="[domainValidate]"
                            :readonly="ro && !viewTeaser"
                            :required="createNew || onModeration"
                            @input="setLp2($event)"
                        >
                        </v-text-field>

                        <v-col cols="12">
                          <v-row v-if="$route.params.section === 'Mgid'">
                            <v-col cols="12" sm="9">
                              <v-combobox multiple label="Tags"
                                          chips
                                          :readonly="ro && !viewTeaser"
                                          v-model="thisTags_2" :items="tagsList"
                                          :required="thisAbtest_on && !thisIsDisabledTags_2 && (createNew || onModeration)"
                                          :disabled="thisIsDisabledTags_2"
                                          @change="delimit_2"
                                          :delimiters="[',',' ']"
                                          :rules="[validateTags_2]"
                              >
                              </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-row>
                                <v-col cols="12" sm="4" class="mr-0 pr-0">
                                  <v-checkbox color="red"
                                              v-model="thisIsDisabledTags_2"
                                              :readonly="ro && !viewTeaser"
                                              class="pl-2"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="8"
                                       class="ml-0 pl-0 pt-8 text-center">
                                  <span class="text-caption pl-2 text-center">Disable Tags</span>
                                </v-col>
                              </v-row>

                            </v-col>
                          </v-row>


                          <v-text-field
                              v-model="thisImage_2"
                              label="Image"
                              type="string"
                              :required="createNew || onModeration"
                              :rules="[imageValidate]"
                              :readonly="(ro && !viewTeaser) || isMgidModerate"
                              prepend-icon="mdi-magnify"
                              @click:prepend="openChooseImageDialog(false)"
                              @change="onChangeImageUrl(thisImage_2, false)"
                          >
                          </v-text-field>

                          <!--                          <v-text-field-->
                          <!--                              v-model="thisImage_2"-->
                          <!--                              :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser"-->
                          <!--                              label="Image - 2"-->
                          <!--                              type="string"-->
                          <!--                              :rules="[image2Validate]"-->
                          <!--                              required-->
                          <!--                              prepend-icon="mdi-magnify"-->
                          <!--                              @click:prepend="openChooseImageDialog(false)"-->
                          <!--                          ></v-text-field>-->
                        </v-col>

                        <v-col cols="12" :sm="teams.length>1? 2 : 3"
                               v-if="isVisibleMgidIdInputs">
                        </v-col>
                        <v-col cols="12" :sm="teams.length>1? 2 : 3"
                               v-if="!isVisibleMgidIdInputs">
                          <v-text-field
                              label="MGID teaser ID - 2"
                              type="string"
                              v-model="thisMgid_id_2"
                              :readonly="((ro && !viewTeaser) || thisDeleteFirstTeaser) || isMgidModerate"
                              :rules="[mgidId2Validate]"
                              required
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-autocomplete label="PP - 2"
                                          v-model="thisPp_2"
                                          :items="pps"
                                          :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser"
                                          :rules="[pp2Validate]">
                            required
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete label="Owner - 2"
                                          v-model="thisOwner_2"
                                          :items="thisTeam.value"
                                          :disabled="!thisTeam.value || thisTeam.value.length === 0"
                                          :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser"
                                          :rules="[ownerValidate]"
                                          required
                          >

                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-if="isVisibleAdultTitle">
                        <v-col cols="12">
                          <v-text-field
                              v-bind:value="thisTitle_2"
                              @input="setTitle2($event)"
                              :readonly="(ro && !viewTeaser) || thisDeleteFirstTeaser || isMgidModerate"
                              label="Title - 2"
                              type="string"
                              :rules="[title2Validate]"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="thisBrand_2"
                              label="Brand - 2"
                              type="string"
                              :rules="[brandValidate]"
                              :readonly="ro && !viewTeaser"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>

            <v-tab-item value="tab-target">
              <TargetEditor v-if="tabs === 'tab-target'"
                            :teaser-id="this_id"
                            :groups-raw="groupsRaw"
                            :mode="mode"
              ></TargetEditor>
            </v-tab-item>

            <v-tab-item value="tab-tasks">
              <v-row style="min-height: 200px">
                <v-container fluid>
                  <v-data-table
                      :headers="headersTasks"
                      :items="tasks"
                      :page.sync="page"
                      :loading="loading"
                      :server-items-length="total"
                      @page-count="pageCount = $event"
                      hide-default-footer
                      dense
                  >
                    <template v-slot:item.stas_time_done="{ item }">
                      <span v-if="item.stas_done === 0" class="red--text">Waite...</span>
                      <span v-else>{{ item.stas_time_done }}</span>
                      <v-tooltip bottom v-if="item.stas_done === 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-on="on" v-bind="attrs"
                                 @click="taskTypeForDelete = 'main'; taskForDelete = item; confirmDeleteTask = true">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete task</span>
                      </v-tooltip>

                    </template>
                  </v-data-table>
                </v-container>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-ab-tasks">
              <v-row style="min-height: 200px">
                <v-container fluid>
                  <v-data-table
                      :headers="headersAbTasks"
                      :items="tasksAbTest"
                      :page.sync="page"
                      :loading="loading"
                      :server-items-length="total"
                      @page-count="pageCount = $event"
                      hide-default-footer
                      dense
                  >
                    <template v-slot:item.task="{ item }">
                      <span v-if="item.task === '1'"><v-icon>mdi-numeric-1-circle-outline</v-icon></span>
                      <span v-else-if="item.task === '2'"><v-icon>mdi-numeric-2-circle-outline</v-icon></span>
                      <span v-else-if="item.task === '3'">
                        <v-icon>mdi-numeric-1-circle-outline</v-icon>
                        <v-icon>mdi-delete</v-icon>
                      </span>
                      <span v-else-if="item.task === '4'">
                        <v-icon>mdi-numeric-2-circle-outline</v-icon>
                        <v-icon>mdi-delete</v-icon>
                      </span>

                    </template>
                    <template v-slot:item.stas_time_done="{ item }">
                      <span v-if="item.stas_done === 0" class="red--text">Waite...</span>
                      <span v-else>{{ item.stas_time_done }}</span>
                      <v-tooltip bottom v-if="item.stas_done === 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-on="on" v-bind="attrs"
                                 @click="taskTypeForDelete = 'ab_test'; taskForDelete = item; confirmDeleteTask = true">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete task</span>
                      </v-tooltip>


                    </template>
                  </v-data-table>
                </v-container>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-eternal-updates">
              <v-row style="min-height: 200px">
                <v-container fluid>
                  <v-data-table
                      :headers="headersTeaserEternalUpdates"
                      :items="teaserEternalUpdates"
                      :page.sync="page"
                      :loading="loading"
                      :server-items-length="total"
                      @page-count="pageCount = $event"
                      hide-default-footer
                      dense
                      @click:row="onClickRowTeaserEternalUpdates"
                  >
                    <template v-slot:item.data="{ item }">
                      <v-btn icon @click.stop="onClickViewExternalUpdateItem(item)">
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.diffChips="{ item }">
                      <v-hover v-slot:default="{ hover }">
                        <div :style="{'cursor': hover ? 'pointer': 'default'}">
                          <v-chip v-for="i in item.diffChips" :key="i.name" class="mx-1 my-1">
                            <strong class="pr-1">{{ i.name }}</strong>
                            <div>
                              {{ i.current }}<br>
                              {{ i.new }}<br>
                            </div>
                            <br>
                          </v-chip>
                        </div>
                      </v-hover>

                    </template>
                    <!--                    <template v-slot:item.task="{ item }">-->
                    <!--                      <span v-if="item.task === '1'"><v-icon>mdi-numeric-1-circle-outline</v-icon></span>-->
                    <!--                      <span v-else-if="item.task === '2'"><v-icon>mdi-numeric-2-circle-outline</v-icon></span>-->
                    <!--                      <span v-else-if="item.task === '3'">-->
                    <!--                        <v-icon>mdi-numeric-1-circle-outline</v-icon>-->
                    <!--                        <v-icon>mdi-delete</v-icon>-->
                    <!--                      </span>-->
                    <!--                      <span v-else-if="item.task === '4'">-->
                    <!--                        <v-icon>mdi-numeric-2-circle-outline</v-icon>-->
                    <!--                        <v-icon>mdi-delete</v-icon>-->
                    <!--                      </span>-->

                    <!--                    </template>-->
                    <!--                    <template v-slFcancelot:item.stas_time_done="{ item }">-->
                    <!--                      <span v-if="item.stas_done === 0" class="red&#45;&#45;text">Waite...</span>-->
                    <!--                      <span v-else>{{ item.stas_time_done }}</span>-->
                    <!--                      <v-tooltip bottom v-if="item.stas_done === 0">-->
                    <!--                        <template v-slot:activator="{ on, attrs }">-->
                    <!--                          <v-btn icon small v-on="on" v-bind="attrs"-->
                    <!--                                 @click="taskTypeForDelete = 'ab_test'; taskForDelete = item; confirmDeleteTask = true">-->
                    <!--                            <v-icon>mdi-delete</v-icon>-->
                    <!--                          </v-btn>-->
                    <!--                        </template>-->
                    <!--                        <span>Delete task</span>-->
                    <!--                      </v-tooltip>-->


                    <!--                    </template>-->
                  </v-data-table>
                </v-container>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-schedule">
              <ScheduleEditor v-model="thisScheduleEnabled" :id="id" :schedule="schedule"
                              @change="$emit('do-task')"></ScheduleEditor>
            </v-tab-item>

            <v-tab-item value="tab-auction-stat">
              <v-row>
                <v-col cols="3">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="430px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          label="Date or Date range"
                          v-model="dateRangeText"
                          prepend-icon="mdi-calendar"
                          @click:prepend="menu = true"
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>

                    </template>
                    <v-card flat>
                      <v-row no-gutters>
                        <v-col cols="4">

                          <v-list dense>
                            <!--                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>-->
                            <v-subheader class="justify-center">
                              <v-icon>mdi-calendar-range-outline</v-icon>
                            </v-subheader>
                            <v-list-item-group
                                v-model="selectedFastDateRange"
                                color="primary"
                            >
                              <v-list-item
                                  v-for="(item, i) in selectedFastDateRangeItems"
                                  :key="i"
                              >
                                <!--                        <v-list-item-icon>-->
                                <!--                          <v-icon v-text="item.icon"></v-icon>-->
                                <!--                        </v-list-item-icon>-->
                                <v-list-item-content
                                    @click="onClickFastDateInterval(item.value)">
                                  <v-list-item-title
                                      v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>

                          </v-list>
                          <!--                                                    <v-switch-->
                          <!--                                                            v-if="$store.getters['user/isAdmin']"-->
                          <!--                                                            v-model="switch1" label="Refresh" class="pl-6" color="red"-->
                          <!--                                                            :disabled="isRunningBackdatingUpdateSpent"></v-switch>-->
                        </v-col>
                        <v-col cols="8">
                          <v-date-picker
                              v-model="dates"
                              :show-current="todayDate()"
                              reactive
                              first-day-of-week="1"
                              range
                          ></v-date-picker>
                          <!--                  <v-spacer></v-spacer>-->
                          <div class="text-right">
                            <v-btn text color="primary"
                                   @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn text
                                   color="primary"
                                   @click="$refs.menu.save(dates);onclickOkPeekDate()"
                            >OK
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      v-model="siteId"
                      dense
                      hide-details
                      label="Site Id"
                      type="string"
                      clearable
                      @click:clear="siteId = null"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="min-height: 200px">
                <v-container fluid>
                  <v-data-table
                      :headers="headersAuctionStat"
                      :items="teaserAuctionStat"
                      :page.sync="page"
                      :loading="loading"
                      :server-items-length="total"
                      dense
                      @click:row="onClickRowAuctionStat"
                  >
                    <template v-slot:item.percent="{ item }">
                      <b v-if="item.code === 'lose'">{{ item.percent }}</b>
                      <span v-else>{{ item.percent }}</span> %
                    </template>
                  </v-data-table>
                  <highcharts v-if="dspAuctionStatChartVisible" class="stock"
                              :options="highchartsOptions"></highcharts>
                </v-container>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-cLimit-achieved">
              <v-row>
                <v-col cols="3">
                </v-col>
              </v-row>
              <v-row style="min-height: 200px">
                <v-container fluid>
                  <v-data-table
                      :headers="headersClimitAchieved"
                      :items="climitAchievedItems"
                      :page.sync="page"
                      :loading="loading"
                      :items-per-page.sync="itemsPerPage"
                      :server-items-length="total"
                      :sort-by.sync="climitAchievedSortBy"
                      :sort-desc.sync="climitAchievedSortDesc"
                      @update:sort-by="onUpdateSortByClimitAchieved"
                      @update:sort-desc="onUpdateSortDescClimitAchieved"
                      dense
                  >
                    <!--                    <template v-slot:item.percent="{ item }">-->
                    <!--                      <b v-if="item.code === 'lose'">{{ item.percent }}</b>-->
                    <!--                      <span v-else>{{ item.percent }}</span> %-->
                    <!--                    </template>-->
                  </v-data-table>
                </v-container>
              </v-row>
            </v-tab-item>
          </v-tabs-items>


        </v-card-text>


        <!--          <v-tabs-->
        <!--              fixed-tabs-->
        <!--              background-color="indigo"-->
        <!--              dark-->
        <!--              v-model="tabs"-->
        <!--          >-->
        <!--            <v-tab value="form">-->
        <!--              -->
        <!--            </v-tab>-->
        <!--            <v-tab value="form">-->
        <!--              -->
        <!--            </v-tab>-->
        <!--          </v-tabs>-->
        <!--        </v-card-text>-->
        <v-card-actions v-if="tabs === 'tab-teaser'">
          <v-spacer></v-spacer>
          <v-btn v-if="!ro || allowEdit" color="primary" text @click="dialog = false">Cancel</v-btn>

          <v-btn v-if="createNew" color="green darken-1" :disabled="!valid" @click="createNewTeaser">
            Send to Moderation
            <v-icon>mdi-send</v-icon>
          </v-btn>


          <v-btn v-if="!createNew && allowSendModerateAgain" color="green darken-1" :disabled="!valid"
                 @click="sendModerateAgain">
            Send to Moderation
            <v-icon>mdi-send</v-icon>
          </v-btn>
          <!--        <v-btn  color="primary" @click="createNewTeaser" :disabled="!valid">Create</v-btn>-->

          <!--        <v-btn  color="primary" @click="createNewTeaser" :disabled="!valid">Create</v-btn>-->
          <v-btn
              v-if="allowCancelModeration"
              color="red lighten-2" @click="cancelModeration()">
            Cancel moderation
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <!--          <v-btn v-if="allowEdit" color="primary" @click="createNewTeaser" :disabled="!valid">-->
          <!--            UPDATE-->
          <!--            <v-icon>mdi-content-save-outline</v-icon>-->
          <!--          </v-btn>-->

          <v-btn v-if="allowDelete" color="red darken-1" @click="confirmDeleteDialog = true">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn v-if="allowModerate" color="green darken-1" :disabled="!valid" @click="doModeration">
            Start
            <v-icon>mdi-airplane-takeoff</v-icon>
          </v-btn>

          <v-btn
              v-if="(viewTeaser && !onModeration && !createNew && tabs === 'tab-teaser' && !thisDeleteFirstTeaser) || (on_off === 0 && !viewTeaser && !onModeration && !createNew && tabs === 'tab-teaser' && !thisDeleteFirstTeaser)"
              color="green darken-1"
              :disabled="!(allowCreateTask && valid)"
              @click="doUpdateTeaser">
            Update teaser
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn v-if="thisDeleteFirstTeaser" color="green darken-1" @click="doUpdateTeaser">
            Delete first teaser
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--        -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/utils/api'
// import { IFACE_DSP_TEASERS_ON_MODERATION_OFF, IFACE_DSP_TEASERS_ON_MODERATION_ON } from '@/store/actions/iface'
import TargetEditor from './TargetEditor.vue'
import DialogAddNewImage from './DialogAddNewImage.vue'
import DialogAddNewVideo from './DialogAddNewVideo.vue'
import ScheduleEditor from './ScheduleEditor.vue'
import {DateTime} from "luxon";
import {encode} from "js-base64";
import VideoPlayer from "./VideoPlayer.vue";
import {IFACE_DSP_TEASERS_REFRESH_ON} from "../../../store/actions/iface";


export default {
  name: 'MenuAddTeaser',
  components: {VideoPlayer, TargetEditor, DialogAddNewImage, DialogAddNewVideo, ScheduleEditor},
  props: {

    openImageDialogEditor: {default: ''},
    countries: {default: []},
    languages: {default: []},
    languagesISO: {default: []},
    offers: {default: []},
    iabCategories: {default: []},
    landingTypes: {default: [``]},
    pps: {default: []},
    tagsList: {default: []},
    userAgentList: {default: []},
    allowedDSPTeaserLinks: {default: []},
    dspModes: {default: {}},
    retarget_modeItems: {default: []},

    imgHosts: {default: {}},

    country: {
      default: ''
    },
    on_off: {
      default: -1
    },
    scheduleEnabled: {
      default: 0
    },
    schedule: {
      default: ''
    },
    mode: {
      default: 0
    },

    roi_for_sources: {
      default: 0
    },

    roi_for_users: {
      default: 0
    },

    turbo: {
      default: 0
    },
    c_limit: {
      default: 0
    },

    c_limit_r: {
      default: 0
    },

    aggression: {
      default: 100
    },

    achievedClimitCount: {
      default: 0
    },
    start_mcpm: {
      default: 0
    },
    spend_limit_daily: {
      default: 0
    },
    lead_limit_daily: {
      default: 0
    },
    no_leads: {
      default: false
    },
    cat: {
      default: ''
    },
    retarget_mode: {
      default: 1
    },
    lang: {
      default: ''
    },
    lang_filter: {
      default: ''
    },
    ad_type: {
      default: ''
    },
    landing_type: {
      default: ''
    },
    offer: {
      default: ''
    },
    payout: {
      default: 0
    },
    approve: {
      default: 50
    },
    button_text: {
      default: ''
    },
    manual_opti: {
      default: 1
    },
    dep: {
      default: ''
    },
    team: {
      default: 0
    },
    owner: {
      default: ''
    },
    pp: {
      default: ''
    },
    id: {
      default: null
    },
    mgid_id: {
      default: null
    },
    link: {
      default: ''
    },
    lp: {
      default: ''
    },
    title: {
      default: ''
    },
    brand: {
      default: ''
    },
    tags: {
      default: ''
    },
    image: {
      default: ''
    },
    abtest_on: {
      default: false
    },
    pp_2: {
      default: ''
    },
    owner_2: {
      default: ''
    },
    mgid_id_2: {
      default: null
    },
    link_2: {
      default: ''
    },
    lp_2: {
      default: ''
    },
    title_2: {
      default: ''
    },
    brand_2: {
      default: ''
    },
    image_2: {
      default: ''
    },
    tags_2: {
      default: ''
    },
    isReadyModeration: {
      default: false
    },

    cat_id: {
      default: null
    },

    os_filter: {
      default: ''
    },

    createNew: {
      default: false
    },

    viewTeaser: {
      default: false
    },
    copy: {
      default: false
    },
    readOnly: {
      default: false
    },

    waitTask: {
      default: 0
    },

    adult: {
      default: 0
    },

    ad_format: {
      default: 0
    },

    waitAbTask: {
      default: 0
    },

    waitAbTaskDeleteFirstTeaser: {
      default: 0
    },

    value: {
      default: false
    },

    tab: {
      default: 'tab-teaser'
    },

    metaData: {
      default: false
    },

    folder: {
      default: false
    },

    videoOptions: {
      default: false
    },
    videoOptions_2: {
      default: false
    },
    stas_done: {
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      tabs: null,

      ro: this.readOnly,
      allowedDSPTeaserLinksRegexp: [],

      headersTeaserEternalUpdates: [
        {
          text: 'id',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'diff',
          value: 'diffChips',
          align: 'center',
          sortable: false
        },
        // {text: 'data', value: 'data'},
        {
          text: 'timestamp',
          value: 'timestamp',
          align: 'center',
          sortable: false,
        },
        {
          text: 'view',
          value: 'data',
          align: 'center',
          sortable: false,
        }
      ],

      headersTeaserEternalUpdatesCreateTaskUpdates: [
        {
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          align: 'center',
          sortable: false,
          value: 'current',
        },
        // {text: 'status', value: 'status'},
        // {text: 'diff', value: 'diffChips'},
        // {text: 'data', value: 'data'},
        // {text: 'timestamp', value: 'timestamp'},
      ],

      headersTeaserEternalUpdatesCreateTaskDst: [
        {
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          align: 'center',
          sortable: false,
          value: 'mgid_id',
          text: 'mgid_id',
        },
        {
          align: 'center',
          sortable: false,
          value: 'mgid_id_2',
          text: 'mgid_id_2',
        },
        // {text: 'status', value: 'status'},
        // {text: 'diff', value: 'diffChips'},
        // {text: 'data', value: 'data'},
        // {text: 'timestamp', value: 'timestamp'},
      ],

      headersAbTasks: [
        {
          text: 'operation_id',
          align: 'start',
          sortable: false,
          value: 'operation_id',
        },
        {text: 'Teaser', value: 'task'},
        {text: 'PP', value: 'pp'},
        {text: 'Owner', value: 'owner'},
        {text: 'Mgid Id', value: 'mgid_id'},
        {text: 'Link', value: 'link'},
        {text: 'Title', value: 'title'},
        {text: 'Image', value: 'image'},
        {text: 'Brand', value: 'brand'},
        {text: 'Tags', value: 'tags'},
        {text: 'Time done', value: 'stas_time_done'},
      ],

      headersTargetUa: [
        {
          text: 'Site Id',
          align: 'start',
          value: 'uid',
        },
        {text: 'Target', value: 'group_id'},
        {text: 'Target', value: 'target'}
      ],

      headersAuctionStat: [],
      headersAuctionStatWithOutPercent: [
        {
          text: 'Site Id',
          align: 'start',
          sortable: false,
          value: 'siteId',
        },
        {text: 'Count', value: 'count'},
        {text: 'Description', sortable: false, value: 'description'},
        {text: 'Code', sortable: false, value: 'code'},
      ],

      headersAuctionStatWithPercent: [
        {
          text: 'Site Id',
          align: 'start',
          sortable: false,
          value: 'siteId',
        },
        {text: 'Count', value: 'count'},
        {text: 'Percent, %', value: 'percent'},
        {text: 'Description', sortable: false, value: 'description'},
        {text: 'Code', sortable: false, value: 'code'},
      ],

      headersClimitAchieved: [
        {
          text: 'Site Id',
          align: 'start',
          sortable: false,
          value: 'uid',
        },
        {text: 'Os Name', sortable: false, value: 'osName'},
        {text: 'Wages OS, yesterday', sortable: true, value: 'wagesOs'},
        {text: 'Wages SiteId, last 30 days', sortable: true, value: 'wagesTotal'},
        {text: 'OS ID', sortable: false, value: 'osId'}
      ],

      adultItems: [
        {text: 'No', value: 0},
        {text: 'Yes', value: 1},
      ],

      ad_formatItems: [
        {text: 'Teaser', value: 0},
        {text: 'Banner', value: 1},
      ],

      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      options: {itemsPerPage: 15},

      tasks: [],
      tasksAbTest: [],
      teaserEternalUpdates: [],
      teaserAuctionStat: [],

      climitAchievedItems: [],

      climitAchievedSortBy: 'wagesOs',
      climitAchievedSortDesc: true,


      // allTargetUa: [],
      // targetUa: [],
      // targetUaSelectable: false,
      // targetUaSelection: [],
      // targetUaGroup: undefined,

      targetsRaw: [],
      groupsRaw: [],

      // targetUaList: [],
      // loading: false,
      // loadingTargetUa: false,
      // activeTargetUa: [],
      // activeAllTargetUa: [],
      // activeTargetUaGroup: [],
      // allTargetUaSelectable: false,
      // allTargetUaSelection: [],
      // openTargetUa: [],
      // openAllTargetUa: [],
      // openTargetUaGroup: [],

      // targetUaGroupSelectable: false,
      // targetUaGroupSelection: [],

      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      total: 0,

      pageTargetUa: 1,
      pageCountTargetUa: 0,
      itemsPerPageTargetUa: 15,
      totalTargetUa: 0,

      valid: false,
      formAddImage: false,

      internalIabCategories: [],

      thisDeleteFirstTeaser: false,
      thisCountry: this.country,
      thisOn_Off: this.on_off,
      thisScheduleEnabled: this.scheduleEnabled,
      thisNo_leads: this.no_leads,
      thisMode: this.mode,
      thisRoiForSources: this.roi_for_sources,
      thisRoiForUsers: this.roi_for_users,
      thisTurbo: this.turbo,
      thisClimit: this.c_limit,
      thisClimitR: this.c_limit_r,
      thisAggression: this.aggression,
      thisStartMcpm: this.start_mcpm,
      thisSpendLimitDaily: this.spend_limit_daily,
      thisLeadLimitDaily: this.lead_limit_daily,
      thisCat: this.cat,
      thisRetarget_mode: this.retarget_mode,
      thisCatId: this.cat_id,
      thisUserAgent: [],
      thisLang: this.lang,
      thisLangFilter: this.lang_filter,
      thisAd_type: this.ad_type,
      thisLanding_type: this.landing_type,
      thisOffer: this.offer,
      isValidFullOfferName: false,
      thisPayout: this.payout,
      thisApprove: this.approve,
      thisButton_text: this.button_text,
      thisManual_opti: this.manual_opti,
      thisPp: this.pp,
      thisOwner: this.owner,
      this_id: this.id,
      thisMgid_id: this.mgid_id,
      thisLink: '',
      thisLp: this.lp,
      rawLink: '',
      thisTitle: this.title,
      thisBrand: this.brand,
      thisImage: this.image,
      thisTags: this.tags.split(',').filter(v => v !== ''),
      thisAbtest_on: this.abtest_on,
      thisPp_2: this.pp_2,
      thisOwner_2: this.owner_2,
      thisMgid_id_2: this.mgid_id_2,
      thisLink_2: this.link_2,
      thisLp_2: this.lp_2,
      thisTitle_2: this.title_2,
      thisBrand_2: this.brand_2,
      thisImage_2: this.image_2,
      thisTags_2: this.tags_2.split(',').filter(v => v !== ''),

      thisAdult: this.adult,
      thisAd_format: this.ad_format,
      thisVideoOptions: this.videoOptions,
      thisVideoOptions_2: this.videoOptions_2,

      thisIsReadyModeration: this.isReadyModeration,
      isVisibleValidationErrorDialog: false,
      confirmDeleteDialog: false,

      confirmDeleteTask: false,

      isVisibleCreateTaskFromExternalUpdates: false,
      selectedExternalUpdateItem: false,
      selectedCreateTaskForExternalUpdate: [],

      selectedCreateTaskForExternalUpdateDstSelected: [],
      selectedCreateTaskForExternalUpdateDst: [],
      selectedCreateTaskForExternalUpdateDstLoading: false,

      isVisibleViewExternalUpdateItem: false,
      selectedExternalUpdateItemDataPretty: null,

      isVisibleResultOfCreatedTaskForCallback: false,

      headersOfCreatedDspTaskForCallback: [
        {
          text: 'Created',
          align: 'center',
          sortable: false,
          value: 'result',
        },
        {
          text: 'id',
          align: 'center',
          sortable: false,
          value: 'offer_id',
        },
        {
          text: 'offer',
          align: 'center',
          sortable: false,
          value: 'offer',
        },
        {
          text: 'AD type',
          align: 'center',
          sortable: false,
          value: 'ad_type',
        },
        {
          text: 'Landing type',
          align: 'center',
          sortable: false,
          value: 'landing_type',
        },
      ],
      resultOfCreatedDspTaskForCallback: [],

      headersOfCreatedAbTaskForCallback: [
        {
          text: 'Created',
          align: 'center',
          sortable: false,
          value: 'result',
        },
        {
          text: 'id',
          align: 'center',
          sortable: false,
          value: 'offer_id',
        },
        {
          text: 'offer',
          align: 'center',
          sortable: false,
          value: 'offer',
        },
        {
          text: 'Teaser',
          align: 'center',
          sortable: false,
          value: 'task',
        },
        {
          text: 'Title',
          align: 'center',
          sortable: false,
          value: 'title',
        },
        {
          text: 'Tags',
          align: 'center',
          sortable: false,
          value: 'tags',
        }
      ],
      resultOfCreatedAbTaskForCallback: [],

      taskTypeForDelete: null,
      taskForDelete: {},

      errorMessage: '',

      depTeams: null,

      teamsLocal: {value: []},

      thisTeam: {value: []},

      init: false,

      chooseImageDialogFirstRun: true,
      uploadNewImage: false,
      chooseImageDialog: false,
      chooseImageDialogForImg1: false,

      selectedImage: '',
      images: [],
      pageImages: 1,
      pageImagesTotal: 0,

      imagesPerPage: 12,
      imagesPerPageItems: [12, 24, 36, 48, 60, 72, 84, 96, 108],
      imageDialogFormat: 'withAspectRatio',
      imageType: 'native',

      selectedTags: [],

      snackbar: false,
      snackbarText: '',

      dialogAddNewImage: false,
      downloadImageFromUrl: '',
      uploadImageFile: null,

      waitAddNewImage: false,

      imgTags: [],
      imgTagsWithRating: [],
      tag1: '',
      tag2: '',
      tag3: '',

      dialogEditImageTags: false,
      dialogEditVideoTags: false,

      isDisabledTags: false,
      isDisabledTags_2: false,

      thisIsDisabledTags: false,
      thisIsDisabledTags_2: false,

      allowSaveThisLang: false,
      savedThisLang: false,

      isVisibleSelectDepDialog: false,

      dspDep: null,

      menu: false,
      selectedFastDateRange: 0,
      selectedFastDateRangeItems: [
        {text: 'Today', value: 'today'},
        {text: 'Yesterday', value: 'yesterday'},
        {text: 'This month', value: 'thisMonth'},
        {text: 'Last month', value: 'lastMonth'},
      ],
      dates: [],
      siteId: null,

      dspAuctionStatChartVisible: false,
      highchartsOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Loss notice URL called by the exchange',
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: {point.code} : <b>{point.count}</b> as <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'Code',
          colorByPoint: true,
          data: []
        }]
      },

      thisFolder: this.folder,
      folderItems: [
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Archive',
          value: 'archive'
        },
        {
          text: 'Delete',
          value: 'deleted'
        }
      ],
      loadingSetFolder: false,
      confirmDeleteTeaser: false,


      // retarget_modeItems: [
      //   {
      //     text: '0 - Off',
      //     value: 0
      //   },
      //   {
      //     text: '1 - All widgets + ROI mode',
      //     value: 1
      //   },
      //   {
      //     text: '2 - Only “on” widgets + only retarget',
      //     value: 2
      //   },
      //   {
      //     text: '3 - All widgets + ROI > 0',
      //     value: 3
      //   },
      // ]

      aggressionItems: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
    }
  },

  async created() {
    if (this.os_filter.length > 0) {
      const os_filter = this.os_filter.split(',').map(id => parseInt(id))
      this.thisUserAgent = this.userAgentList.filter(element => os_filter.includes(element.group_id))
    }

    const date = this.todayDate()
    this.dates = [date, date]
  },

  async mounted() {
    if (this.createNew) {
      if (this.dspDepsAndTeams.length > 1) {
        this.isVisibleSelectDepDialog = true
      } else {
        await this.initDialog()
        this.dialog = true
      }
    } else {
      if (this.onModeration) {
        this.dspDep = this.dspDepsAndTeams.find(d => d.text === this.dep)
        // console.log('', this.team, this.dep, this.dspDep)
      } else {
        this.dspDep = this.dspDepsAndTeams.find(d => d.text === this.dep)
        await this.initDialog()
        this.dialog = true
      }
    }

    if (this.dialog && this.tab !== null) {
      this.tabs = this.tab
    }

    // if (this.copy) {
    //   this.$refs.form.validate()
    // }
    // this.$refs.form.validate()
  },

  computed: {
    dateRangeText() {
      if (this.isToday()) return 'Today'
      if (this.isYesterday()) return 'Yesterday'
      if (this.isThisMonth()) return 'This month'
      if (this.isLastMonth()) return 'Last month'
      return this.dates.join(' ~ ')
    },
    showDspAuctionStat() {
      switch (this.$route.params.section) {
        case 'Outbrain' :
        case 'AdNow' :
        case 'Mgid' :
          return true
        default:
          return false
      }
    },

    headersTasks: {
      get() {
        switch (this.$route.params.section) {
          case 'Mgid' :
          case 'AdNow' :
            return [
              {
                text: 'operation_id',
                align: 'start',
                sortable: false,
                value: 'operation_id',
              },
              {text: 'On/Off', value: 'on_off'},
              {text: 'Mode', value: 'mode'},
              {text: 'ROI/Sources', value: 'roi_for_sources'},
              {text: 'ROI/Users', value: 'roi_for_users'},
              {text: 'Retarget mode', value: 'retarget_mode'},
              {text: 'Turbo', value: 'turbo'},
              {text: 'Payout', value: 'payout'},
              {text: 'Approve', value: 'approve'},
              {text: 'Only Action', value: 'no_leads'},
              {text: 'C Limit', value: 'c_limit'},
              {text: 'C Limit R', value: 'c_limit_r'},
              {text: 'Aggression', value: 'aggression'},
              {text: 'Start MCPM', value: 'start_mcpm'},
              {text: 'AD type', value: 'ad_type'},
              {text: 'Landing type', value: 'landing_type'},
              {text: 'Manual Oopti', value: 'manual_opti'},
              {text: 'Button text', value: 'button_text'},
              {text: 'OS Filter', value: 'os_filter'},
              {text: 'Time done', value: 'stas_time_done'}
            ]
          default:
            return [
              {
                text: 'operation_id',
                align: 'start',
                sortable: false,
                value: 'operation_id',
              },
              {text: 'On/Off', value: 'on_off'},
              {text: 'Mode', value: 'mode'},
              {text: 'Payout', value: 'payout'},
              {text: 'Approve', value: 'approve'},
              {text: 'Only Action', value: 'no_leads'},
              {text: 'C Limit', value: 'c_limit'},
              {text: 'Start MCPM', value: 'start_mcpm'},
              {text: 'Button text', value: 'button_text'},
              {text: 'OS Filter', value: 'os_filter'},
              {text: 'Time done', value: 'stas_time_done'},
            ]
        }
      }
    },

    isVisibleAdTypeAndLandTypeAndIabCat() {
      return !(this.$route.params.section === 'Exoclick' ||
          this.$route.params.section === 'AdNow' ||
          this.$route.params.section === 'Geniee' ||
          this.$route.params.section === 'AdMixer' ||
          this.$route.params.section === 'RevContent' ||
          this.$route.params.section === 'Bambar' ||
          this.$route.params.section === 'Outbrain' ||
          this.$route.params.section === 'Midas' ||
          this.$route.params.section === 'Kadam' ||
          this.$route.params.section === 'ContentExchange' ||
          this.$route.params.section === 'Geniee' ||
          this.$route.params.section === 'Yengo' ||
          this.$route.params.section === 'Adsterra' ||
          this.$route.params.section === 'Popin')
    },

    isVisibleAdTypeAndLandType() {
      return !(this.$route.params.section === 'SmartyAds')
    },

    isVisibleLandType() {
      if (this.$route.params.section === 'Mgid') {
        return false
      }
      return !(this.$route.params.section === 'SmartyAds')
    },

    isVisibleAdultType() {
      return this.$route.params.section === 'Exoclick' || this.$route.params.section === 'Adsterra'
    },

    isVisibleAdultTitle() {
      // return this.$route.params.section !== 'Geniee'
      return true
    },

    isVisibleLandPage() {
      return this.$route.params.section === 'Outbrain'
    },

    isVisibleMgidIdInputs() {
      return this.$route.params.section === 'Exoclick' ||
          this.$route.params.section === 'AdNow' ||
          this.$route.params.section === 'Geniee' ||
          this.$route.params.section === 'AdMixer' ||
          this.$route.params.section === 'RevContent' ||
          this.$route.params.section === 'Bambar' ||
          this.$route.params.section === 'Outbrain' ||
          this.$route.params.section === 'Outbrain' ||
          this.$route.params.section === 'Midas' ||
          this.$route.params.section === 'Kadam' ||
          this.$route.params.section === 'ContentExchange' ||
          this.$route.params.section === 'Yengo' ||
          this.$route.params.section === 'Adsterra' ||
          this.$route.params.section === 'Popin' ||
          this.$route.params.section === 'SmartyAds'
    },

    isBlanc() {
      return this.$route.params.section === 'AdNow' ||
          this.$route.params.section === 'Geniee' ||
          this.$route.params.section === 'Yengo' ||
          this.$route.params.section === 'Popin'
    },

    isVisibleTurbo() {
      // console.log('this.ro && !this.viewTeaser', this.ro, this.viewTeaser, this.viewTeaser)
      return (this.$route.params.section === 'Mgid' || this.$route.params.section === 'AdNow' || this.$route.params.section === 'ContentExchange') && this.viewTeaser && !this.onModeration
    },

    isVisibleIABCategory() {
      return this.$route.params.section === 'AdMixer' || this.$route.params.section === 'RevContent' ||
          this.$route.params.section === 'Outbrain' ||
          this.$route.params.section === 'Bambar' ||
          this.$route.params.section === 'Geniee' || this.$route.params.section === 'ContentExchange' ||
          this.$route.params.section === 'Midas' || this.$route.params.section === 'Kadam'
    },

    onModeration: {
      get() {
        return this.$store.getters['iface/isDspTeasersOnModeration']
      }
    },

    allowEdit() {
      return this.on_off === 1 && !this.copy && this.onModeration &&
          (
              this.$store.getters['user/isDspModerator'] ||
              this.$store.getters['user/isAllowModerateTeaser'](this.thisTeam.id)
          )
    },

    allowCancelModeration() {
      return !this.copy && this.onModeration && this.thisIsReadyModeration &&
          (
              this.$store.getters['user/isDspModerator'] ||
              this.$store.getters['user/isAllowModerateTeaser'](this.thisTeam.id) ||
              (this.currentOwner === this.thisOwner && this.$store.getters['user/getDspTeam'].some(id => id === this.thisTeam.id))
          )
    },

    allowModerate() {
      return (
          !this.viewTeaser && !this.copy && this.thisIsReadyModeration &&
          (
              this.$store.getters['user/isDspModerator'] ||
              this.$store.getters['user/isAllowModerateTeaser'](this.thisTeam.id) ||
              (
                  this.$store.getters['user/getIsDspTeamModerator'] &&
                  this.$store.getters['user/getDspTeam'].some(id => id === this.thisTeam.id)
              )
          ))
    },

    allowDelete() {
      return (
          !this.copy &&
          this.onModeration &&
          (
              this.$store.getters['user/isDspModerator'] ||
              this.$store.getters['user/isAllowModerateTeaser'](this.thisTeam.id) ||
              (this.currentOwner === this.thisOwner && this.$store.getters['user/getDspTeam'].some(id => id === this.thisTeam.id))
          ))
    },

    allowSendModerateAgain() {
      return (!this.viewTeaser && !this.copy && this.onModeration && !this.thisIsReadyModeration)

    },

    currentOwner() {
      return this.$store.getters['user/getUserName']
    },
    isDspModerator() {
      return this.$store.getters['user/isDspModerator']
    },

    isMgidModerate() {
      return this.$route.params.section === 'Mgid'
    },

    // dep: {
    //   get () {
    //     return this.$store.getters['iface/getDspDep']
    //   }
    // },

    depsAndTeams: {
      get() {
        return this.$store.getters['iface/getDspDeps']
      }
    },

    dspDepsAndTeams: {
      get() {
        return this.$store.getters['iface/getDspDeps']
      }
    },

    teams: {
      get() {
        if (this.dspDep) {
          return this.teamsLocal
        } else {
          return this.$store.getters['iface/getDspDepTeams']
        }
      }
    },

    modeItems: {
      get() {
        // if ((this.createNew || this.onModeration)) {
        //   return [25, 31, 32]
        // }
        if (Object.prototype.hasOwnProperty.call(this.dspModes, this.$route.params.section)) {
          return this.dspModes[this.$route.params.section]
        }
        return this.dspModes.default
      }
    },


    isEnableRoiForSources: {
      get() {
        return [23, 25, 27, 30, 31, 32, 33, 34].includes(this.thisMode)
      }
    },

    isEnableRoiForUsers: {
      get() {
        return [0, 3, 20, 23, 25, 27, 30, 31, 32, 33, 34, 35].includes(this.thisMode)
      }
    },

    // roiItems: {
    //   get() {
    //     return Array.from({length: 53}, (value, index) => {
    //       if (index < 31) {
    //         return index
    //       } else if (index < 45) {
    //         return (index - 30) * 5 + 30
    //       } else {
    //         return (index - 44) * 50 + 100
    //       }
    //     })
    //   }
    // },

    roiSourcesItems: {
      get() {
        const r = []
        for (let i = 0; i <= 1000;) {
          r.push(i)
          if (i < 0) {
            i += 10
            continue
          }
          if (i < 30) {
            i += 1
            continue
          }
          if (i < 100) {
            i += 5
            continue
          }
          i += 10
        }
        // r[0] = r[0] + 1
        return r
      }
    },

    roiUsersItems: {
      get() {
        const r = []
        for (let i = -100; i <= 1000;) {
          r.push(i)
          if (i < 0) {
            i += 10
            continue
          }
          if (i < 30) {
            i += 1
            continue
          }
          if (i < 100) {
            i += 5
            continue
          }
          i += 10
        }
        r[0] = r[0] + 1
        return r
      }
    },

    thisTurboItems: {
      get() {
        return new Array(50).fill(0).map((_, i) => i)
      }
    },

    isAllowChangeManual_opti: {
      get() {
        // if (this.createNew) {
        return [30, 31, 32, 33].includes(this.thisMode)
        // }
        // return [30, 31, 32, 33].includes(this.mode)
      }
    },

    // dspUserTeam: {
    //   get () {
    //     return this.$store.getters['user/getDspTeam']
    //   }
    // },

    // allowCreateTask: {
    //   get () {
    //     const intThisPayout = parseInt(this.thisPayout)
    //     // console.log('allowCreateTask i', intThisPayout)
    //     if (Number.isFinite(intThisPayout)) {
    //       if (Number(intThisPayout) === intThisPayout && intThisPayout % 1 === 0 && ((this.payout !== intThisPayout) && (intThisPayout > 0))) {
    //         return true
    //       }
    //     } else {
    //       const f = parseFloat(this.thisPayout)
    //       // console.log('allowCreateTask f', f)
    //       if (Number(f) === f && f % 1 === 0 && (Math.round(this.payout * 100) !== Math.round(f * 100)) && f > 0) {
    //         return true
    //       }
    //     }
    //
    //     // console.log('allowCreateTask i', Number(this.thisNo_leads))
    //     if (Number(this.thisNo_leads) !== this.no_leads) {
    //       return true
    //     }
    //
    //     const intThisApprove = parseInt(this.thisApprove)
    //     // console.log('allowCreateTask i', intThisPayout)
    //     if (Number.isFinite(intThisApprove)) {
    //       if (Number(intThisApprove) === intThisApprove && intThisApprove % 1 === 0 &&
    //           ((this.approve !== intThisApprove) && (intThisApprove > 0 && intThisApprove <= 100))) {
    //         return true
    //       }
    //     } else {
    //       const f = parseFloat(this.thisApprove)
    //       // console.log('allowCreateTask f', f)
    //       if (Number(f) === f && f % 1 === 0 && (Math.round(this.thisApprove * 100) !== Math.round(f * 100)) &&
    //           (f > 0 && f <= 100.0)) {
    //         return true
    //       }
    //     }
    //
    //     if (this.thisOn_Off !== this.on_off) {
    //       return true
    //     }
    //
    //     if (this.thisMode !== this.mode) {
    //       return true
    //     }
    //
    //     return false
    //   }
    // },

    allowCreateTask: {
      get() {
        // if (this.thisDeleteFirstTeaser) {
        //   return true
        // }

        const intThisPayout = Math.round(parseInt(this.thisPayout * 10000))
        // console.log('allowCreateTask i', intThisPayout)
        if (Number.isFinite(intThisPayout)) {
          if (Number(intThisPayout) === intThisPayout && intThisPayout % 1 === 0 &&
              ((Math.round(this.payout * 10000) !== intThisPayout) && (intThisPayout > 0))) {
            return true
          }
        } else {
          const f = parseFloat(this.thisPayout)
          // console.log('allowCreateTask f', f)
          if (Number(f) === f && f % 1 === 0 && (Math.round(this.payout * 10000) !== Math.round(f * 10000)) && f > 0) {
            return true
          }
        }

        const intLeadLimitDaily = Math.round(parseInt(this.thisLeadLimitDaily))
        // console.log('allowCreateTask i', intThisPayout)
        if (Number.isFinite(intLeadLimitDaily)) {
          if (Number(intLeadLimitDaily) === intLeadLimitDaily &&
              (intLeadLimitDaily >= 0 && intLeadLimitDaily !== this.lead_limit_daily)
          ) {
            return true
          }
        }

        const intSpendLimitDaily = Math.round(parseInt(this.thisSpendLimitDaily))
        // console.log('allowCreateTask i', intThisPayout)
        if (Number.isFinite(intSpendLimitDaily)) {
          if (Number(intSpendLimitDaily) === intSpendLimitDaily &&
              (intSpendLimitDaily >= 0 && intSpendLimitDaily !== this.spend_limit_daily)
          ) {
            return true
          }
        }

        const intAggression = Math.round(parseInt(this.thisAggression))
        // console.log('allowCreateTask i', intThisPayout)
        if (Number.isFinite(intAggression)) {
          if (Number(intAggression) === intAggression && (intAggression !== this.aggression)
          ) {
            return true
          }
        }

        const intThisClimit = Math.round(parseInt(this.thisClimit * 1000))
        if (Number.isFinite(intThisClimit)) {
          if (Number(intThisClimit) === intThisClimit && intThisClimit % 1 === 0 &&
              ((Math.round(this.c_limit * 1000) !== intThisClimit) && (intThisClimit > 0))) {
            return true
          }
        } else {
          const f = parseFloat(this.thisClimit)
          if (Number(f) === f && f % 1 === 0 && (Math.round(this.c_limit * 1000) !== Math.round(f * 1000)) && f > 0) {
            return true
          }
        }

        // const intThisClimitR = Math.round(parseInt(this.thisClimitR * 1000))
        const intThisClimitR = Math.round(Number(this.thisClimitR) * 1000)
        if (Number.isFinite(intThisClimitR)) {
          if (Number(intThisClimitR) === intThisClimitR && intThisClimitR % 1 === 0 &&
              ((Math.round(this.c_limit_r * 1000) !== intThisClimitR) && (intThisClimitR > 0))) {
            return true
          }
        } else {
          const f = parseFloat(this.thisClimitR)
          if (Number(f) === f && f % 1 === 0 && (Math.round(this.c_limit_r * 1000) !== Math.round(f * 1000)) && f > 0) {
            return true
          }
        }

        const intThisStartMcpm = Math.round(parseInt(this.thisStartMcpm * 1000))
        if (Number.isFinite(intThisStartMcpm)) {
          if (Number(intThisStartMcpm) === intThisStartMcpm && intThisStartMcpm % 1 === 0 &&
              ((Math.round(this.start_mcpm * 1000) !== intThisStartMcpm) && (intThisStartMcpm > 0))) {
            return true
          }
        } else {
          const f = parseFloat(this.thisStartMcpm)
          if ((Math.round(this.start_mcpm * 1000) !== Math.round(f * 1000)) && f > 0) {
            return true
          }
        }

        // console.log('allowCreateTask i', Number(this.thisNo_leads))
        if (Number(this.thisNo_leads) !== this.no_leads) {
          return true
        }

        const intThisApprove = parseInt(Math.round(this.thisApprove * 1000))
        // console.log('allowCreateTask i', intThisPayout)
        if (Number.isFinite(intThisApprove)) {
          if (Number(intThisApprove) === intThisApprove &&
              ((this.approve * 1000 !== intThisApprove) && (intThisApprove > 0))) {
            // if (((this.thisPp === 'tonik' || this.thisPp_2 === 'tonik') ||
            //     (this.thisPp === 'tonic' || this.thisPp_2 === 'tonic')) && intThisApprove <= 300000) {
            //   return true
            // if (
            //     (this.$route.params.section === 'Mgid' || this.$route.params.section === 'Adsterra')
            //     && intThisApprove <= 300000) {
            //   return true
            // } else
            if (intThisApprove <= 10000000) {
              return true
            }
            return false
          }
        }

        if (this.thisOn_Off !== this.on_off) {
          return true
        }

        if (this.thisMode !== this.mode) {
          return true
        }

        if (this.isEnableRoiForSources && (this.roi_for_sources !== this.thisRoiForSources)) {
          return true
        }

        if (this.isEnableRoiForUsers && (this.roi_for_users !== this.thisRoiForUsers)) {
          return true
        }


        if (this.thisTurbo !== this.turbo) {
          return true
        }

        if (this.thisAbtest_on === true && this.abtest_on === 0) {
          if (this.linkValidate(this.thisLink_2) === true &&
              this.imageValidate(this.thisImage) === true &&
              this.title2Validate(this.thisTitle_2) &&
              this.pp2Validate(this.thisPp_2) &&
              this.ownerValidate(this.thisOwner_2) &&
              this.title2Validate(this.thisTitle_2)
          ) {
            return true
          }
        }

        if (this.$route.params.section === 'Mgid') {
          if (this.thisAd_type !== this.ad_type) {
            return true
          }

          if (this.thisLanding_type !== this.landing_type) {
            return true
          }
        }

        if (this.isAllowChangeManual_opti) {
          if (this.manual_opti !== this.thisManual_opti) {
            return true
          }
        }

        const os_filter = this.thisUserAgent.length === 0 || this.thisUserAgent.length === this.userAgentList.length ? '' : this.thisUserAgent.map(ua => ua.group_id).join(',')

        if (os_filter !== this.os_filter) {
          return true
        }

        if (this.thisBrand !== this.brand && this.brandValidate(this.thisBrand)) {
          return true
        }

        if (this.thisButton_text !== this.button_text && this.buttonTextValidate(this.thisButton_text)) {
          return true
        }

        if (this.thisBrand_2 !== this.brand_2 && this.brandValidate(this.thisBrand_2)) {
          return true
        }

        if (this.thisLink !== this.link && this.linkValidate(this.thisLink) === true) {
          return true
        }

        if (this.thisLink_2 !== this.link_2 && this.linkValidate(this.thisLink_2) === true) {
          return true
        }

        if (this.thisImage !== this.image && this.imageValidate(this.thisImage) === true) {
          return true
        }

        if (this.thisImage_2 !== this.image_2 && this.imageValidate(this.thisImage) === true) {
          return true
        }

        if (this.thisTitle !== this.title) {
          return true
        }

        if (this.thisTitle_2 !== this.title_2) {
          return true
        }

        if (Boolean(this.thisAbtest_on) !== Boolean(this.abtest_on)) {
          return true
        }

        if (this.thisPp !== this.pp) {
          return true
        }

        if (this.thisPp_2 !== this.pp_2) {
          return true
        }

        if (this.thisOwner !== this.owner) {
          return true
        }

        if (this.thisOwner_2 !== this.owner_2) {
          return true
        }

        if (this.thisMgid_id !== this.mgid_id) {
          return true
        }

        if (this.thisMgid_id_2 !== this.mgid_id_2) {
          return true
        }

        if (!this.isDisabledTags && this.thisIsDisabledTags) {
          return true
        }

        if (!this.isDisabledTags_2 && this.thisIsDisabledTags_2) {
          return true
        }

        if (this.isDisabledTags && !this.thisIsDisabledTags) {
          if (this.validateTags(this.thisTags)) {
            return true
          }
        } else {
          if (this.validateTags(this.thisTags)) {
            if (this.thisTags.join(',') !== this.tags) {
              return true
            }
          }
        }

        if (this.isDisabledTags_2 && !this.thisIsDisabledTags_2) {
          if (this.validateTags_2(this.thisTags_2)) {
            return true
          }
        } else {
          if (this.validateTags_2(this.thisTags_2)) {
            if (this.thisTags_2.join(',') !== this.tags_2) {
              return true
            }
          }
        }

        if (this.thisRetarget_mode !== this.retarget_mode) {
          return true
        }

        return false
      }
    },
    isDisabledImageSelectBtn() {

      if (this.chooseImageDialogForImg1 && this.selectedImage.url === this.thisImage) {
        return true
      }
      if (!this.chooseImageDialogForImg1 && this.selectedImage.url === this.thisImage_2) {
        return true
      }

      if (Object.prototype.hasOwnProperty.call(this.selectedImage, 'url') && this.selectedImage.url !== '') {
        return false
      }

      return true
    },

    getImgHost: {
      get() {
        return this.imgHosts[0]
      }
    },

    // uaFilter (item, search, textKey) {
    //   console.log('(item, search, textKey)', item, search, textKey)
    // }
    refreshDspTeasersTable: {
      get() {
        return this.$store.getters['iface/getDspTeasersRefreshStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_TEASERS_REFRESH_ON)
        // else
        //   this.$store.commit(IFACE_DSP_TEASERS_REFRESH_OFF)
      }
    }
  },

  beforeDestroy() {
    this.$emit('input', false)
  },

  methods: {

    todayDate() {
      // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
      // console.log('cccccccccccc', c, )
      return DateTime.local().setZone(this.tz).toISODate()
    },

    yesterdayDate() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
    },

    thisMonth() {
      return DateTime.local().setZone(this.tz).startOf('month').toISODate()
    },

    isToday() {
      if (this.dates.length === 1)
        return this.todayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

      return false
    },

    isYesterday() {
      if (this.dates.length === 1)
        return this.yesterdayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

      return false
    },

    isThisMonth() {
      if (this.dates.length === 2)
        return this.thisMonth() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isLastMonth() {
      const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
      const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

      if (this.dates.length === 2)
        return startMonth === this.dates[0] && endMonth === this.dates[1]
      return false
    },

    onClickFastDateInterval(value) {
      switch (value) {
        case 'today' :
          this.dates = [this.todayDate()]
          break
        case 'yesterday' :
          this.dates = [this.yesterdayDate()]
          break
        case 'thisMonth' :
          this.dates = [this.thisMonth(), this.todayDate()]
          break
        case 'lastMonth' :
          this.dates = [
            DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]
          break
        default:
          throw `Unknown ${value}`
      }
    },

    onclickOkPeekDate() {
      this.getAuctionStat()
    },

    onClickRowAuctionStat(value) {
      if (!this.siteId) {
        this.siteId = value.siteId.toString()
      }
    },

    onUpdateSortDescClimitAchieved(v) {
      console.log('onUpdateSortDescClimitAchieved', v)
      this.getClimitAchievedItems()
    },

    onUpdateSortByClimitAchieved(v) {
      console.log('onUpdateSortByClimitAchieved', v)
      this.getClimitAchievedItems()
    },


    async initDialog() {
      this.setLink(this.link)

      if (this.isVisibleLandPage) {
        this.setLp(this.lp)
        this.setLp2(this.lp_2)
      }

      if (this.teams.value.length === 1) {
        this.thisTeam = this.teams.value[0]
        // this.thisTeam_2 = this.teams.value[0]
      }

      if (this.team !== null) {
        const team = this.teams.value.find(t => t.id === this.team)

        this.init = true
        if (team) {
          this.thisTeam = team
        }
      }

      // if (this.createNew) {
      //   this.thisCountry = null
      //   this.thisCountry = this.country
      // }
      if (this.onModeration) {
        if (this.createNew) {
          if (['us', 'vn', 'pl', 'ua', 'id', 'it'].includes(this.thisCountry)) {
            this.thisMode = 31
          } else {
            this.thisMode = 25
          }
        }
        if (this.cat_id === 0) {
          this.internalIabCategories = [this.cat]
          this.thisCatId = this.cat
        } else {
          this.internalIabCategories = this.iabCategories
          const r = this.iabCategories.find(c => c.value === this.cat_id)
          if (r) {
            this.$set(this, 'thisCatId', r)
          }
        }
      } else {
        if (this.createNew) {
          this.internalIabCategories = this.iabCategories
          if (['us', 'vn', 'pl', 'ua', 'id', 'it'].includes(this.thisCountry)) {
            this.thisMode = 31
          } else {
            this.thisMode = 25
          }
        } else {
          this.internalIabCategories = [this.cat]
          this.thisCatId = this.cat
        }
      }

      this.allowedDSPTeaserLinksRegexp = this.allowedDSPTeaserLinks.map(l => new RegExp(`^https://${l}`))

      if (!this.ro && !this.viewTeaser) {
        this.isDisabledTags = false
        this.isDisabledTags_2 = false
      } else {
        if (this.thisTags.length === 0) {
          this.isDisabledTags = true
          this.thisIsDisabledTags = true
        }

        if (this.thisTags_2.length === 0) {
          this.isDisabledTags_2 = true
          this.thisIsDisabledTags_2 = true
        }
      }

      try {
        const res = await api.dspCatalogTargetUa()
        if (!res.data.error) {
          this.$set(this, 'groupsRaw', res.data.ua)

        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('dspTeaserNew', e)
      }

      if (this.openImageDialogEditor === 'image') {
        this.openChooseImageDialog(true)
      }

      if (this.openImageDialogEditor === 'image_2') {
        this.openChooseImageDialog(false)
      }

    },
    getMode() {
      return this.thisMode
      // switch (this.$route.params.section) {
      //   case 'Mgid' :
      //   case 'AdNow' :
      //   case 'Yengo' :
      //   case 'Popin' :
      //     return this.thisMode
      //   case 'Adsterra' :
      //     return 25
      //   default:
      //     return undefined
      // }
    },

    getRoiForSources() {
      // console.log('getRoiForSources', this.isEnableRoiForSources)
      if (this.isEnableRoiForSources) {
        return this.thisRoiForSources
      } else {
        return 0
      }
    },

    getRoiForUsers() {
      // console.log('isEnableRoiForUsers', this.isEnableRoiForUsers)
      if (this.isEnableRoiForUsers) {
        return this.thisRoiForUsers
      } else {
        return 0
      }
    },

    getCat() {
      switch (this.$route.params.section) {
        case 'Exoclick' :
        case 'Adsterra' :
        case 'AdNow' :
        case 'Geniee' :
        case 'Yengo' :
        case 'Popin' :
          return ''
        default:
          return this.thisCatId.cat
      }
    },

    getCatId() {
      switch (this.$route.params.section) {
        case 'Exoclick' :
        case 'Adsterra' :
        case 'AdNow' :
        case 'Geniee' :
        case 'Yengo' :
        case 'Popin' :
          return 0
        default:
          return this.thisCatId.value
      }
    },

    getAdType() {
      switch (this.$route.params.section) {
        case 'Exoclick' :
        case 'Adsterra' :
        case 'AdNow' :
        case 'Geniee' :
        case 'Yengo' :
        case 'Popin' :
          return ''
        default:
          return this.thisAd_type
      }
    },

    getMgidId() {
      switch (this.$route.params.section) {
        case 'Exoclick' :
        case 'Adsterra' :
        case 'AdMixer' :
        case 'RevContent' :
        case 'Bambar' :
        case 'Outbrain' :
        case 'Midas' :
        case 'Kadam' :
        case 'ContentExchange' :
        case 'AdNow' :
        case 'Geniee' :
        case 'Yengo' :
        case 'Popin' :
        case 'SmartyAds' :
          return 0
        default:
          return parseInt(this.thisMgid_id)
      }
    },

    getMgidId2() {
      switch (this.$route.params.section) {
        case 'Exoclick' :
        case 'Adsterra' :
        case 'RevContent' :
        case 'Bambar' :
        case 'Outbrain' :
        case 'Midas' :
        case 'Kadam' :
        case 'ContentExchange' :
        case 'AdMixer' :
        case 'AdNow' :
        case 'Geniee' :
        case 'Yengo' :
        case 'Popin' :
          return 0
        default:
          return parseInt(this.thisMgid_id_2)
      }
    },

    getTeaserObject() {
      const r = {}

      if (this.$route.params.section === 'Exoclick' || this.$route.params.section === 'Adsterra') {
        Object.assign(r, {adult: this.thisAdult, ad_format: this.thisAd_format})
      }

      if (this.$route.params.section === 'Mgid') {
        if (this.thisTags.length > 0) {
          r.tags = this.thisTags.filter(v => v !== '').join(',')
        }
        if (this.thisAbtest_on && this.thisTags_2.length > 0) {
          r.tags_2 = this.thisTags_2.filter(v => v !== '').join(',')
        }
      }

      if (this.thisLangFilter === null || this.thisLangFilter === undefined) {
        r.lang_filter = ''
      } else {
        r.lang_filter = this.thisLangFilter
      }


      if (this.isVisibleLandPage) {
        r.lp = this.thisLp

        if (this.thisAbtest_on) {
          r.lp_2 = this.thisLp_2
        }
      }


      const os_filter = this.thisUserAgent.length === 0 || this.thisUserAgent.length === this.userAgentList.length === 0 ? '' : this.thisUserAgent.map(ua => ua.group_id).join(',')

      let country
      let offer

      if (this.thisCountry === 'uk') {
        country = 'uk'
        offer = this.thisOffer.trim().toLowerCase().replace(/_gb$/, '_uk')
      } else if (this.thisCountry === 'gb') {
        country = 'uk'
        offer = this.thisOffer.trim().toLowerCase().replace(/_gb$/, '_uk')
      } else {
        country = this.thisCountry
        offer = this.thisOffer.trim().toLowerCase()
      }
      if (this.thisAbtest_on)
        return {
          networkName: this.$route.params.section,
          id: this.this_id,
          country,
          lang: this.thisLang,
          mode: this.getMode(),
          roi_for_sources: this.getRoiForSources(),
          roi_for_users: this.getRoiForUsers(),
          no_leads: this.thisNo_leads,
          c_limit: this.thisClimit,
          c_limit_r: this.thisClimitR,
          aggression: this.thisAggression,
          start_mcpm: this.thisStartMcpm,
          cat: this.getCat(),
          cat_id: this.getCatId(),
          retarget_mode: this.thisRetarget_mode,
          ad_type: this.getAdType(),
          landing_type: this.thisLanding_type,
          os_filter,
          offer,
          payout: parseFloat(this.thisPayout),
          approve: this.thisApprove,
          manual_opti: this.isAllowChangeManual_opti ? Number(this.thisManual_opti) : undefined,
          button_text: this.thisButton_text,
          pp: this.thisPp,
          dep: this.teams.text,
          team: this.thisTeam.id,
          owner: this.thisOwner,
          mgid_id: this.getMgidId(),
          link: this.thisLink.trim(),
          title: this.thisTitle.trim(),
          brand: this.thisBrand.trim(),
          image: this.thisImage.trim(),
          abtest_on: this.thisAbtest_on,
          pp_2: this.thisPp_2,
          link_2: this.thisLink_2.trim(),
          owner_2: this.thisOwner_2,
          mgid_id_2: this.getMgidId2(),
          title_2: this.thisTitle_2.trim(),
          brand_2: this.thisBrand_2.trim(),
          image_2: this.thisImage_2.trim(),
          ...r
        }
      else
        return {
          networkName: this.$route.params.section,
          id: this.this_id,
          country,
          lang: this.thisLang,
          mode: this.getMode(),
          roi_for_sources: this.getRoiForSources(),
          roi_for_users: this.getRoiForUsers(),
          no_leads: !!this.thisNo_leads,
          c_limit: this.thisClimit,
          c_limit_r: this.thisClimitR,
          aggression: this.thisAggression,
          start_mcpm: this.thisStartMcpm,
          cat: this.getCat(),
          cat_id: this.getCatId(),
          retarget_mode: this.thisRetarget_mode,
          ad_type: this.getAdType(),
          landing_type: this.thisLanding_type,
          os_filter,
          offer,
          payout: parseFloat(this.thisPayout),
          approve: this.thisApprove,
          manual_opti: this.isAllowChangeManual_opti ? Number(this.thisManual_opti) : undefined,
          button_text: this.thisButton_text,
          pp: this.thisPp,
          dep: this.teams.text,
          team: this.thisTeam.id,
          owner: this.thisOwner,
          mgid_id: this.getMgidId(),
          link: this.thisLink.trim(),
          title: this.thisTitle.trim(),
          brand: this.thisBrand.trim(),
          image: this.thisImage.trim(),
          abtest_on: 0,
          pp_2: '',
          owner_2: '',
          link_2: '',
          mgid_id_2: 0,
          title_2: '',
          image_2: '',
          ...r
        }
    },

    getImageUrl(img, aspect = '1x1') {
      let imgHost
      if (/^\/KateDep\/\d{4}\/\d{2}\/\d{2}\/\d{1,}\.(jpeg|png|gif)$/i.test(img)) {
        imgHost = 'https://img.amdevsdsp.org/'
      } else {
        imgHost = this.getImgHost
      }

      if (img.match(/^\/video/)) {
        return imgHost + img + `/${aspect}`
      } else if (img.match(/^http/)) {
        return img
      } else {
        return imgHost + img + `/${aspect}/512`
      }
    },

    onChangeImageUrl(img, isFirst) {
      if (isFirst) {
        // console.log(this.getImgHost + img + `/1x1`)
        if (img.match(/^\/video/)) {
          this.thisVideoOptions = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img + `/2x1`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        }
      } else {
        if (img.match(/^\/video/)) {
          this.thisVideoOptions_2 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img + `/2x1`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        }
      }
    },

    async createNewTeaser() {
      try {
        const res = await api.dspTeaserNew(this.getTeaserObject())
        if (!res.data.error) {
          // console.info('dspTeaserNew', res)
          this.$emit('saved')
          this.dialog = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('dspTeaserNew', e)
      }
    },

    async sendModerateAgain() {
      try {
        const res = await api.dspTeaserSendModerateAgain(this.getTeaserObject())
        if (!res.data.error) {
          // console.info('sendModerateAgain', res)
          this.$emit('saved')
          this.dialog = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('dspTeaserNew', e)
      }
    },

    async cancelModeration() {
      try {
        const res = await api.dspTeaserCancelModerate({id: this.this_id, networkName: this.$route.params.section})
        if (!res.data.error) {
          this.$emit('cancel-moderation')
          // this.dialog = false
          this.ro = false
          this.thisIsReadyModeration = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('cancelModeration', e)
      }
    },

    async deleteFromModeration() {
      try {
        const res = await api.dspTeaserDeleteFromModeration(this.this_id, this.$route.params.section)
        if (!res.data.error) {
          this.$emit('delete-from-moderation')
          this.dialog = false
          this.confirmDeleteDialog = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('cancelModeration', e)
      }
    },

    async deleteTask() {
      // console.log('deleteTask', this.taskForDelete)

      try {
        const res = await api.dspTeaserDeleteTask(this.taskTypeForDelete, this.taskForDelete.operation_id, this.$route.params.section)
        if (!res.data.error) {
          await this.refreshTasks()
          this.$emit('do-task')
          this.confirmDeleteTask = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('deleteTask', e)
      }
    },

    async doModeration() {
      try {
        const res = await api.dspTeaserModerate({id: this.this_id, networkName: this.$route.params.section})
        if (!res.data.error) {
          this.$emit('do-moderation')
          this.dialog = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('doModeration', e)
      }
    },

    async doUpdateTeaser() {
      try {

        const task = {
          networkName: this.$route.params.section,
          offer_id: this.id,
          offer: this.offer
        }

        if (this.thisDeleteFirstTeaser) {
          const abTestTask_1 = {
            networkName: this.$route.params.section,
            offer_id: this.id,
            offer: this.offer,
            task: 3
          }

          const res = await api.dspTeaserCreateAbTask(abTestTask_1)

          if (!res.data.error) {
            this.$emit('do-task')
            this.dialog = false
            return
          } else {
            this.errorMessage = res.data.message
            this.isVisibleValidationErrorDialog = true
          }
        } else {
          if (this.thisOn_Off !== this.on_off) {
            task.on_off = Boolean(this.thisOn_Off)
          }

          if (this.$route.params.section === 'Mgid' || this.$route.params.section === 'AdNow') {
            if (this.thisTurbo !== this.turbo) {
              task.turbo = this.thisTurbo
            }
          }

          if (this.thisMode !== this.mode) {
            task.mode = this.thisMode
          }

          if (this.isAllowChangeManual_opti) {
            if (this.manual_opti !== Number(this.thisManual_opti)) {
              task.manual_opti = Number(this.thisManual_opti)
            }
          }

          if (this.isEnableRoiForSources) {
            // if (this.roi_for_sources === 0 && this.roi_for_sources === this.thisRoiForSources) {
            //   task.roi_for_sources = 0
            // }
            if (this.roi_for_sources !== this.thisRoiForSources) {
              task.roi_for_sources = this.thisRoiForSources
            }
          }

          if (this.isEnableRoiForUsers) {
            // if (this.roi_for_users === 0 && this.roi_for_users === this.thisRoiForUsers) {
            //   task.roi_for_users = 0
            // }
            if (this.roi_for_users !== this.thisRoiForUsers) {
              task.roi_for_users = this.thisRoiForUsers
            }
          }

          if (this.thisRetarget_mode !== this.retarget_mode) {
            task.retarget_mode = this.thisRetarget_mode
          }

          if (this.thisAd_type !== this.ad_type) {
            task.ad_type = this.thisAd_type
          }

          if (this.thisLanding_type !== this.landing_type) {
            task.landing_type = this.thisLanding_type
          }

          if (this.thisButton_text !== this.button_text) {
            task.button_text = this.thisButton_text.trim()
          }

          const f = parseFloat(this.thisPayout)
          if (!isNaN(f)) {
            if ((Math.round(this.payout * 10000) !== Math.round(f * 10000)) && f > 0) {
              task.payout = f
            }
          }

          // console.log('allowCreateTask i', Number(this.thisNo_leads))
          if (Number(this.thisNo_leads) !== this.no_leads) {
            task.no_leads = Boolean(this.thisNo_leads)
          }

          const intThisApprove = parseInt(this.thisApprove)
          // console.log('allowCreateTask i', intThisPayout)
          if (Number.isFinite(intThisApprove)) {
            if (Number(intThisApprove) === intThisApprove && intThisApprove % 1 === 0 &&
                ((this.approve !== intThisApprove) && (intThisApprove > 0))) {
              task.approve = intThisApprove
            }
          } else {
            const f = parseFloat(this.thisApprove)
            // console.log('allowCreateTask f', f)
            if (Number(f) === f && f % 1 === 0 && (Math.round(this.thisApprove * 100) !== Math.round(f * 100)) &&
                (f > 0)) {
              task.approve = f
            }
          }

          const intThisClimit = Math.round(parseInt(this.thisClimit * 1000))
          if (Number.isFinite(intThisClimit)) {
            if (((Math.round(this.c_limit * 1000) !== intThisClimit) && (intThisClimit > 0))) {
              task.c_limit = this.thisClimit
            }
          } else {
            const f = parseFloat(this.thisClimit)
            if ((Math.round(this.c_limit * 1000) !== Math.round(f * 1000)) && f > 0) {
              task.c_limit = this.thisClimit
            }
          }

          // const intThisClimitR = Math.round(parseInt(this.thisClimitR) * 1000)
          const intThisClimitR = Number(this.thisClimitR) * 1000
          if (Number.isFinite(intThisClimitR)) {
            if (Number(intThisClimitR) === intThisClimitR && intThisClimitR % 1 === 0 &&
                ((Math.round(this.c_limit_r * 1000) !== intThisClimitR) && (intThisClimitR > -1))) {
              task.c_limit_r = this.thisClimitR
            }
          } else {
            const f = parseFloat(this.thisClimitR)
            if (Number(f) === f && f % 1 === 0 && (Math.round(this.c_limit_r * 1000) !== Math.round(f * 1000)) && f > 0) {
              task.c_limit_r = this.thisClimitR
            }
          }

          const intThisStartMcpm = Math.round(parseInt(this.thisStartMcpm * 1000))
          if (Number.isFinite(intThisStartMcpm)) {
            if (((Math.round(this.start_mcpm * 1000) !== intThisStartMcpm) && (intThisStartMcpm > 0))) {
              task.start_mcpm = this.thisStartMcpm
            }
          } else {
            const f = parseFloat(this.thisStartMcpm)
            if ((Math.round(this.start_mcpm * 1000) !== Math.round(f * 1000)) && f > 0) {
              task.start_mcpm = this.thisStartMcpm
            }
          }

          const intLeadLimitDaily = Math.round(parseInt(this.thisLeadLimitDaily))
          // console.log('allowCreateTask i', intThisPayout)
          if (Number.isFinite(intLeadLimitDaily)) {
            if (Number(intLeadLimitDaily) === intLeadLimitDaily &&
                (intLeadLimitDaily >= 0 && intLeadLimitDaily !== this.lead_limit_daily)
            ) {
              task.lead_limit_daily = intLeadLimitDaily
            }
          }

          const intSpendLimitDaily = Math.round(parseInt(this.thisSpendLimitDaily))
          // console.log('allowCreateTask i', intThisPayout)
          if (Number.isFinite(intSpendLimitDaily)) {
            if (Number(intSpendLimitDaily) === intSpendLimitDaily &&
                (intSpendLimitDaily >= 0 && intSpendLimitDaily !== this.spend_limit_daily)
            ) {
              task.spend_limit_daily = intSpendLimitDaily
            }
          }

          const intAggression = parseInt(this.thisAggression)
          if (Number.isFinite(intAggression)) {
            if (Number(intAggression) === intAggression && (intAggression !== this.aggression)
            ) {
              task.aggression = intAggression
            }
          }

          const os_filter = this.thisUserAgent.length === 0 || this.thisUserAgent.length === this.userAgentList.length ? '' : this.thisUserAgent.map(ua => ua.group_id).join(',')

          // console.log('os_filter', this.thisUserAgent, this.thisUserAgent.map(ua => ua.group_id))

          if (os_filter !== this.os_filter) {
            task.os_filter = os_filter
          }

          if (Object.keys(task).length > 3) {
            const res = await api.dspTeaserCreateTask(task)

            if (!res.data.error) {
              this.$emit('do-task')
              this.dialog = false
              return
            } else {
              this.errorMessage = res.data.message
              this.isVisibleValidationErrorDialog = true
            }
          }

          // adTest task

          const abTestTask_1 = {
            networkName: this.$route.params.section,
            offer_id: this.id,
            offer: this.offer,
            task: 1
          }
          const abTestTask_2 = {
            networkName: this.$route.params.section,
            offer_id: this.id,
            offer: this.offer,
          }

          if (this.thisPp !== this.pp) {
            abTestTask_1.pp = this.thisPp
          }

          if (this.thisOwner !== this.owner) {
            abTestTask_1.owner = this.thisOwner
          }

          if (this.thisMgid_id !== this.mgid_id) {
            abTestTask_1.mgid_id = Number(this.thisMgid_id)
          }

          if (this.thisLink !== this.link) {
            abTestTask_1.link = this.thisLink.trim()
          }

          if (this.thisTitle !== this.title) {
            abTestTask_1.title = this.thisTitle.trim()
          }

          if (this.thisImage !== this.image) {
            abTestTask_1.image = this.thisImage.trim()
          }

          if (this.thisBrand !== this.brand) {
            abTestTask_1.brand = this.thisBrand.trim()
          }

          if (this.isDisabledTags && !this.thisIsDisabledTags) {
            abTestTask_1.tags = this.thisTags.join(',')
          }

          if (!this.isDisabledTags && this.thisIsDisabledTags) {
            abTestTask_1.tags = ''
          }

          if (this.tags !== this.thisTags.join(',')) {
            abTestTask_1.tags = this.thisTags.join(',')
          }

          if (this.abtest_on === 0 && this.thisAbtest_on === true) {
            abTestTask_2.link = this.thisLink_2.trim()
            abTestTask_2.image = this.thisImage_2.trim()
            abTestTask_2.mgid_id = Number(this.thisMgid_id_2)
            abTestTask_2.pp = this.thisPp_2
            abTestTask_2.owner = this.thisOwner_2
            abTestTask_2.title = this.thisTitle_2.trim()
            abTestTask_2.brand = this.thisBrand_2.trim()

            if (this.thisIsDisabledTags_2) {
              abTestTask_2.tags = this.thisTags_2.join(',')
            }

            if (this.thisTags_2.length > 0) {
              abTestTask_2.tags = this.thisTags_2.filter(v => v !== '').join(',')
            }
            // console.log('Enable AB Test ', abTestTask_2)
          } else if (this.abtest_on === 1 && this.thisAbtest_on === false) {
            abTestTask_2.task = 4
            // console.log('Delete 2', abTestTask_2)
          } else {
            if (this.thisLink_2 !== this.link_2) {
              abTestTask_2.link = this.thisLink_2.trim()
            }

            if (this.thisImage_2 !== this.image_2) {
              abTestTask_2.image = this.thisImage_2.trim()
            }

            if (this.thisTitle_2 !== this.title_2) {
              abTestTask_2.title = this.thisTitle_2.trim()
            }

            if (this.thisPp_2 !== this.pp_2) {
              abTestTask_2.pp = this.thisPp_2
            }

            if (this.thisMgid_id_2 !== this.mgid_id_2) {
              abTestTask_2.mgid_id = Number(this.thisMgid_id_2)
            }

            if (this.thisOwner_2 !== this.owner_2) {
              abTestTask_2.owner = this.thisOwner_2
            }

            if (this.thisOwner_2 !== this.owner_2) {
              abTestTask_2.brand = this.thisBrand_2.trim()
            }

            if (this.isDisabledTags_2 && !this.thisIsDisabledTags_2) {
              abTestTask_2.tags = this.thisTags_2.join(',')
            }

            if (!this.isDisabledTags_2 && this.thisIsDisabledTags_2) {
              abTestTask_2.tags = ''
            }

            if (this.tags_2 !== this.thisTags_2.join(',')) {
              abTestTask_2.tags = this.thisTags_2.join(',')
            }

          }

          if (Object.keys(abTestTask_1).length > 4) {
            // console.error('update abTestTask_1', abTestTask_1)

            const res = await api.dspTeaserCreateAbTask(abTestTask_1)

            if (!res.data.error) {
              this.$emit('do-task')
              this.dialog = false
              return
            } else {
              this.errorMessage = res.data.message
              this.isVisibleValidationErrorDialog = true
            }
          }

          // console.error('update abTestTask_2', abTestTask_2)

          if (Object.keys(abTestTask_2).length > 3) {

            if (!Object.prototype.hasOwnProperty.call(abTestTask_2, 'task')) {
              abTestTask_2.task = 2
            }

            // console.error('update abTestTask_2', abTestTask_2)

            const res = await api.dspTeaserCreateAbTask(abTestTask_2)

            if (!res.data.error) {
              this.$emit('do-task')
              this.dialog = false
              return
            } else {
              this.errorMessage = res.data.message
              this.isVisibleValidationErrorDialog = true
            }
          }
        }

      } catch (e) {
        console.error('doUpdateTeaser', e)
      }
    },

    countryValidate(value) {
      if (value) {
        return value.length > 0
      } else {
        return 'Choice country'
      }
    },

    offerValidate(value) {
      if (this.viewTeaser) return true
      if (!this.viewTeaser && this.on_off === 0) return true

      if (value) {
        if (this.offers.indexOf(value) !== -1) return 'Come up with another name'
        if (value.length === 0) return 'Come up with a name'

        const match = /^([a-zA-Z][a-zA-Z0-9]+)_([a-z][a-z])$/.exec(value)

        if (match) {
          if (match[2] !== this.thisCountry) {
            return `GEO: '${match[2]}' not eq '${this.thisCountry}'`
          } else {
            return true
          }
        } else {
          return 'Error offer name'
        }
      } else {
        return 'Come up with a name'
      }
    },

    async validateFullOfferName(offer) {
      console.log('validateFullOfferName', offer)
      // item.isValidFullOfferName = true

      try {
        const r = (await api.dspTeaserValidate({
          kind: 'offer',
          networkName: 'Mgid',
          offer
        })).data
        if (!r.error) {
          this.isValidFullOfferName = true
        } else {
          let msg = ''
          if (r.main) {
            msg += 'Exists offer_id:' + r.main.map(({id}) => id).join(',') + '<BR>'
          }

          if (r.moderation) {
            msg += 'Exists offer_id onModeration:' + r.moderation.map(({id}) => id).join(',')
          }

          this.errorMessage = msg
          this.isVisibleValidationErrorDialog = true
        }
        console.log('validateFullOfferName', r)
        //
      } catch (e) {
        console.error('validateFullOfferName', e)
      }
    },

    adTypeValidate(value) {
      if (this.viewTeaser) return true
      if (!this.viewTeaser && this.on_off === 0) return true

      if (value) {
        return value.length > 0
      } else {
        return 'Choice Ad type'
      }
    },

    landingTypeValidate(value) {
      if (this.viewTeaser) return true
      if (!this.viewTeaser && this.on_off === 0) return true

      if (value) {
        return value.length > 0
      } else {
        return 'Choice Landing type'
      }
    },

    iabCatValidate(value) {
      if (this.viewTeaser) return true
      if (value) {
        return true
      } else {
        return 'Need select'
      }
    },

    payoutValidate(value) {
      const i = parseFloat(value)
      if (Number.isFinite(i)) {
        if (this.$route.params.section === 'Adsterra' || this.$route.params.section === 'AdMixer' ||
            this.$route.params.section === 'RevContent' || this.$route.params.section === 'Outbrain' ||
            this.$route.params.section === 'Midas' ||
            this.$route.params.section === 'Bambar' ||
            this.$route.params.section === 'Geniee' || this.$route.params.section === 'ContentExchange' ||
            this.$route.params.section === 'Kadam') {
          if (i < 0.01)
            return 'Set more than or eq 0.01'
        } else if (this.$route.params.section === 'Mgid') {
          if (i < 0.0001)
            return 'Set more than or eq 0.01'
        } else {
          if (i < 0.1)
            return 'Set more than or eq 0.1'
        }

        return true
      } else {
        return 'Set more than or eq 0.1'
      }
    },

    cLimitValidate(value) {
      const i = parseFloat(value)
      if (Number.isFinite(i)) {
        if (Math.round(i * 100) > 125 || Math.round(i) === 0) {
          return true
        } else {
          return 'Must be > 1.25'
        }
      } else {
        return 'Must number'
      }
    },

    cLimitRValidate(value) {
      const i = Number(value)
      if (Number.isFinite(i)) {
        if (Math.round(i) >= 0) {
          return true
        } else {
          return 'Must be >= 0'
        }
      } else {
        return 'Must number'
      }
    },

    startMcpmValidate(value) {
      const i = parseFloat(value)
      if (Number.isFinite(i)) {
        return true
      } else {
        return 'Must number'
      }
    },

    aggressionValidate(value) {
      const i = parseInt(value)
      if (Number.isFinite(i) && i >= 0) {
        return true
      } else {
        return 'Must number'
      }
    },

    leadLimitDailyValidate(value) {
      const i = parseInt(value)
      if (Number.isFinite(i) && i >= 0) {
        return true
      } else {
        return 'Must number'
      }
    },

    spendLimitDailyValidate(value) {
      const i = parseInt(value)
      if (Number.isFinite(i) && i >= 0) {
        return true
      } else {
        return 'Must number'
      }
    },

    approveValidate(value) {
      const i = parseInt(value)
      if (Number.isFinite(i)) {
        if (i <= 0)
          return 'Set more than 0'
        // if ((this.$route.params.section === 'Mgid' || this.$route.params.section === 'Adsterra')
        //     && i > 1000) {
        //   return 'Set less than 300'
        // } else
        if (i <= 10000) {
          return true
        } else if (i > 1000) {
          return 'Set less than 1000'
        }

        return true
      } else {
        return 'Set more than 0'
      }
    },

    domainValidate(value) {
      if (value === null || value === undefined || value.toString().length === 0) {
        return 'Set valid Domain'
      }

      if (value) {
        try {
          // if (value.match(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/)) {
          if (value.match(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/)) {
            const url = new URL('https://' + value)
            if (value === url.host) {
              return true
            } else {
              return 'Set valid Domain 1'
            }
          } else {
            return 'Set valid Domain 2'
          }
        } catch (e) {
          return 'Set valid Domain 3'
        }
      }
    },

    linkValidate(value) {
      if (value === null || value === undefined || value.toString().length === 0) {
        return 'Set valid URL'
      }
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (!(/^https:\/\//.test(value))) {
        return 'must be https://'
      }
      if (
          // best.peekclickshndrk.com
          // www.shndrktrk.com
          // track.trcsrchd.xyz
          // www.bestbloglk.com
          /best\.peekclickshndrk\.com/.test(value) ||
          /www\.shndrktrk\.com/.test(value) ||
          /track\.trcsrchd\.xyz/.test(value) ||
          /www\.bestbloglk\.com/.test(value) ||
          /www\.btstracklnk\.com/.test(value)
      ) {
        return 'Invalid Domain'
      } else {
        if (this.allowedDSPTeaserLinksRegexp.some(r => r.test(value))) {
          return true
        }
        return 'Not allowed URL'
      }
    },

    buttonTextValidate(value) {
      if (value) {
        return value.length > 0
      } else {
        return 'Not empty'
      }
    },

    validateTags(value) {
      if (this.thisIsDisabledTags) {
        return true
      }
      if (Array.isArray(value) && value.length > 0) {
        if (!value.every(v => this.tagsList.some(t => t === v))) {
          return 'Unknown tag'
        }
        return true
      } else {
        return 'Select tags'
      }
    },

    validateTags_2(value) {
      if (!this.thisAbtest_on) {
        return true
      }
      if (this.thisIsDisabledTags_2) {
        return true
      }
      if (Array.isArray(value) && value.length > 0) {
        if (!value.every(v => this.tagsList.some(t => t === v))) {
          return 'Unknown tag'
        }
        return true
      } else {
        return 'Select tags'
      }

    },

    imageValidate(value) {
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (/^https:\/\/img\.rtbsystem\.org\//.test(value) || /^\/\d+\/\d+\/\d+\//.test(value) || /^\/video\/\d+\/\d+\/\d+\//.test(value) || /^\/KateDep\/\d{4}\/\d{2}\/\d{2}\/\d{1,}\.(jpeg|png|gif)$/i.test(value)) {
        return true
      } else {
        return 'Link must be: https://img.rtbsystem.org or /1/2/3/...or /video/1/2/3/... or /KateDep/...'
      }
    },

    image2Validate(value) {
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (!this.thisAbtest_on) {
        return true
      }
      if (/^https:\/\/img\.rtbsystem\.org\//.test(value) || /^\/\d+\/\d+\/\d+\//.test(value) || /^\/video\/\d+\/\d+\/\d+\//.test(value) || /^\/KateDep\/\d{4}\/\d{2}\/\d{2}\/\d{1,}\.(jpeg|png|gif)$/i.test(value)) {
        return true
      } else {
        return 'Link must be: https://img.rtbsystem.org or /1/2/3/... or /video/1/2/3/... or /KateDep/...'
      }
    },

    mgidIdValidate(value) {
      if (this.viewTeaser) return true
      if (!this.viewTeaser && this.on_off === 0) return true

      if (value == null || value.length === 0) return 'Set teaser ID '
      let i = parseInt(value)
      if (Number.isFinite(i)) {
        if (i <= 0)
          return 'Set more than 0'
        return true
      } else {
        return 'Set more than 0'
      }
    },

    mgidId2Validate(value) {
      if (this.viewTeaser) return true
      if (!this.viewTeaser && this.on_off === 0) return true

      if (!this.thisAbtest_on) {
        return true
      }
      if (value == null || value.length === 0) return 'Set teaser ID '
      let i = parseInt(value)
      if (Number.isFinite(i)) {
        if (i <= 0)
          return 'Set more than 0'
        return true
      } else {
        return 'Set more than 0'
      }
    },

    titleValidate(value) {
      if (value) {
        if (value.length === 0)
          return 'Not empty'
        // if (this.$route.params.section === 'AdNow') {
        //   if (/\{city\}/i.test(value)) {
        //     return 'Not allow macros {city}'
        //   }
        // }
        return true
      } else {
        return 'Not empty'
      }
    },

    title2Validate(value) {
      if (!this.thisAbtest_on) {
        return true
      }
      if (value) {
        if (value.length === 0)
          return 'Not empty'
        // if (this.$route.params.section === 'AdNow') {
        //   if (/\{city\}/i.test(value)) {
        //     return 'Not allow macros {city}'
        //   }
        // }
        return true
      } else {
        return 'Not empty'
      }
    },

    brandValidate(value) {
      if (this.$route.params.section === 'SmartyAds') {
        if (value) {
          if (value.length === 0)
            return 'Not empty'
          return true
        } else {
          return 'Not empty'
        }
      } else if (/https?:\/\//.test(value)) {
        return 'Not URL'
      }
      return true
    },

    ppValidate(value) {
      if (value) {
        return value.length > 0
      } else {
        return 'Select PP'
      }
    },

    depsValidate(value) {
      if (value) {
        if (value.length === 0) return 'Select Dep'
        return true
      } else {
        return 'Select Dep'
      }
    },

    teamValidate(value) {
      if (value) {
        if (value.value.length === 0) return 'Select Team'
        return true
      } else {
        return 'Select Team'
      }
    },

    team2Validate(value) {
      if (value) {
        if (value.value.length === 0) return 'Select Team'
        return true
      } else {
        return 'Select Team'
      }
    },

    pp2Validate(value) {
      if (!this.thisAbtest_on) {
        return true
      }
      if (value) {
        return value.length > 0
      } else {
        return 'Select PP'
      }
    },

    ownerValidate(value) {
      // console.log('ownerValidate', value)
      if (value) {
        if (value.length === 0)
          return 'Select owner'
        return true
      } else {
        return 'Select owner'
      }
    },
    //
    // setLink (v) {
    //   console.log('setLink', v, this.thisLink)
    //   const match = /(^[^?].*)(\?.*)$/.exec(v)
    //   if (match) {
    //     console.log('rawLink', match, match[1].toString(), this.thisLink)
    //     this.$nextTick(() => {
    //       this.$set(this, 'thisLink', match[1].toString())
    //     })
    //
    //   } else {
    //     if (this.thisLink !== v)
    //       this.thisLink = v
    //   }
    // }

    setLink(v) {
      const splited = v.split('?')
      if (splited.length > 0) {
        this.thisLink = splited[0].toString()
      }
    },

    setLink2(v) {
      const splited = v.split('?')
      if (splited.length > 0) {
        this.thisLink_2 = splited[0].toString()
      }
    },

    setLp(v) {
      if (v)
        try {
          let url
          if (v.match(/^https?:\/\//)) {
            url = new URL(v)
          } else {
            url = new URL('https://' + v)
          }

          if (url.host.length > 0) {
            this.thisLp = url.host
          }
        } catch (e) {
          console.log(e)
        }
    },

    setLp2(v) {
      if (v)
        try {
          let url
          if (v.match(/^https?:\/\//)) {
            url = new URL(v)
          } else {
            url = new URL('https://' + v)
          }

          if (url.host.length > 0) {
            this.thisLp_2 = url.host
          }
        } catch (e) {
          console.log(e)
        }
    },

    setTitle(v) {
      if (typeof v === 'string') {
        this.thisTitle = v.replace(/"/g, '\'')
      }
    },

    setTitle2(v) {
      if (typeof v === 'string') {
        this.thisTitle_2 = v.replace(/"/g, '\'')
      }
    },

    async getTeaserTasks() {
      this.loading = true

      const q = {
        networkName: this.$route.params.section,
        id: this.this_id,
        limit: this.itemsPerPage,
        page: this.page - 1,
      }

      try {
        const res = await api.dspTeaserTasks(q)
        this.$set(this, 'total', res.data.total)
        this.$set(this, 'tasks', res.data.tasks)
        // console.info('getTeaserTasks', res.data)
      } catch (e) {
        console.error('getTeaserTasks', e)
      }
      this.loading = false
    },

    isDisabledBtnUpdateTags() {
      return !(
          this.tag1 !== this.selectedImage.tag1 ||
          this.tag2 !== this.selectedImage.tag2 ||
          this.tag3 !== this.selectedImage.tag3
      )
    },

    async onUpdateImageTags() {
      try {
        await api.dspUpdateImgTags({id: this.selectedImage.id, tag1: this.tag1, tag2: this.tag2, tag3: this.tag3})
        this.getImagesTags()
        this.getImagesList()
      } catch (e) {
        console.error('onUpdateImageTags', e)
      }

      this.dialogEditImageTags = false
      this.dialogEditVideoTags = false
    },

    clearTag(tag) {
      this.$nextTick(() => {
        this.$set(this, tag, '')
      })

    },

    // async getTargetUa () {
    //
    //   this.loadingTargetUa = true
    //
    //   const q = {
    //     networkName: this.$route.params.section,
    //     id: this.this_id,
    //     // limit: this.itemsPerPageTargetUa,
    //     limit: 100000,
    //     page: this.pageTargetUa - 1,
    //   }
    //
    //   try {
    //     const res = await api.dspTargetUa(q)
    //     const targets = res.data.targets.reduce((acc, t) => {
    //       const id = 'offer_id ' + t.offer_id + ' ' + t.uid
    //       const i = acc.findIndex(a => a.id === id)
    //       if (i === -1) {
    //         acc.push({
    //           id,
    //           name: t.uid,
    //           group: [t]
    //         })
    //       } else {
    //         acc[i].group.push(t)
    //       }
    //       return acc
    //     }, [])
    //
    //     this.$set(this, 'targetUa', targets)
    //     return []
    //   } catch (e) {
    //     console.error('getTargetUa', e)
    //   }
    //   this.loadingTargetUa = false
    // },

    // async getTargetUaChildren () {
    //   this.loadingTargetUa = true
    //
    //   const q = {
    //     networkName: this.$route.params.section,
    //     id: this.this_id,
    //     limit: this.itemsPerPageTargetUa,
    //     page: this.pageTargetUa - 1,
    //   }
    //
    //   try {
    //     const res = await api.dspTargetUa(q)
    //     // this.$set(this, 'totalTargetUa', res.data.total)
    //     return res.data.targets.map((t) => {
    //       t.id = t.offer_id + ' ' + t.uid
    //       t.name = t.offer_id + ' ' + t.uid
    //       return t
    //     })
    //     // this.$set(this, 'targetUa', targets)
    //     // return this.targetUa
    //     // console.info('getTeaserTasks', res.data)
    //   } catch (e) {
    //     console.error('getTargetUa', e)
    //   }
    //   this.loadingTargetUa = false
    // },

    async getTeaserTasksAbTasks() {
      this.loading = true

      const q = {
        networkName: this.$route.params.section,
        id: this.this_id,
        limit: this.itemsPerPage,
        page: this.page - 1,
      }

      try {
        const res = await api.dspTeaserAbTasks(q)
        this.$set(this, 'total', res.data.total)
        this.$set(this, 'tasksAbTest', res.data.tasks)
        // console.info('getTeaserTasks', res.data)
      } catch (e) {
        console.error('getTeaserTasks', e)
      }
      this.loading = false
    },


    async getAuctionStat() {
      this.loading = true

      let networkName

      switch (this.$route.params.section) {
        case 'AdNow':
          networkName = 'Adnow'
          break
        default:
          networkName = this.$route.params.section
      }

      const q = {
        networkName,
        id: this.this_id,
        limit: this.itemsPerPage,
        page: this.page - 1,
      }

      if (this.dates.length === 1) {
        q.startDate = this.dates[0]
        q.endDate = this.dates[0]
      } else if (this.dates.length === 2) {
        q.startDate = this.dates[0]
        q.endDate = this.dates[1]
      } else
        throw 'Error dates'

      if (this.siteId) {
        q.siteId = this.siteId
      }

      try {
        const res = await api.dspAuctionStat(q)
        this.$set(this, 'total', res.data.total)
        this.$set(this, 'teaserAuctionStat', res.data.stat)


        if (this.siteId) {
          this.dspAuctionStatChartVisible = true

          let total = res.data.stat.find(element => element.code === -1)

          if (total === undefined) {
            total = 0
          } else {
            total = parseInt(total.count)
          }

          const len = res.data.stat.length
          const statReversed = new Array(len)
          let k = 0
          for (; k < len; k++) statReversed[k] = res.data.stat[len - k - 1]

          const [data, totalCountCodes] = statReversed.reduce(([acc, totalCount], item) => {
            if (item.code === -1) {
              const statDataRow = res.data.stat.find(element => element.code === item.code)
              statDataRow.percent = 100
            } else {
              const statDataRow = res.data.stat.find(element => element.code === item.code)

              const count = parseInt(item.count)
              const percent = Math.round((count / total * 100) * 100) / 100
              totalCount += count
              acc.push({
                name: item.description,
                y: percent,
                sliced: true,
                code: item.code,
                count
              })

              statDataRow.percent = percent
            }
            return [acc, totalCount]
          }, [[], 0])


          data.push({
            name: 'Lost requests',
            y: 100 - Math.round((totalCountCodes / total * 100) * 100) / 100,
            sliced: true,
            selected: true,
            code: 'lose',
            count: total - totalCountCodes
          })

          this.teaserAuctionStat.push({
            siteId: '',
            count: total - totalCountCodes,
            description: 'Lost requests',
            code: 'lose',
            percent: Math.round((100 - (totalCountCodes / total * 100)) * 100) / 100
          })

          // console.log(data, totalCountCodes)

          this.$set(this.highchartsOptions.series[0], 'data', data)
          this.headersAuctionStat = this.headersAuctionStatWithPercent

        } else {
          this.headersAuctionStat = this.headersAuctionStatWithOutPercent
          this.dspAuctionStatChartVisible = false
          this.$set(this.highchartsOptions.series[0], 'data', [])
        }

      } catch (e) {
        console.error('getAuctionStat', e)
      }
      this.loading = false
    },

    async getClimitAchievedItems() {
      this.loading = true

      const q = {
        networkName: this.$route.params.section,
        id: this.this_id,
        limit: this.itemsPerPage,
        page: this.page - 1,
      }

      if (this.climitAchievedSortBy) {
        q.sortBy = this.climitAchievedSortBy
        q.sortDesc = this.climitAchievedSortDesc
      }

      if (this.siteId) {
        q.siteId = this.siteId
      }

      try {
        const res = await api.dspGetClimitAchieved(q)

        // console.log(res)

        this.$set(this, 'total', res.data.total)
        this.$set(this, 'climitAchievedItems', res.data.table)


      } catch (e) {
        console.error('getClimitAchievedItems', e)
      }
      this.loading = false
    },

    async getTeaserEternalUpdates() {
      this.loading = true

      const q = {
        networkName: this.$route.params.section,
        campaignId: this.id,
        limit: 10,
        page: 0,
      }

      if (this.mgid_id !== 0) {
        q.teaserIdA = this.mgid_id
      }

      if (this.abtest_on === 1 && this.mgid_id_2 !== 0) {
        q.teaserIdB = this.mgid_id_2
      }

      if (Object.keys(q).length > 4) {
        try {
          const res = await api.dspTeaserExternalUpdates(q)
          this.$set(this, 'total', res.data.total)

          const tags = this.tags.split(',').filter(v => v !== '')
          const tags_2 = this.tags_2.split(',').filter(v => v !== '')

          this.$set(this, 'teaserEternalUpdates', res.data.data.map(i => {
            i.dataParsed = JSON.parse(i.data)
            i.diffChips = []

            if (i.dataParsed.adTypes !== this.ad_type) {
              i.diffChips.push({
                name: 'Ad type',
                field_name: 'ad_type',
                current: this.ad_type,
                new: i.dataParsed.adTypes
              })
            }

            if (i.dataParsed.landingType !== this.landing_type) {
              i.diffChips.push({
                name: 'Landing type',
                field_name: 'landing_type', current: this.landing_type, new: i.dataParsed.landingType
              })
            }

            if (this.mgid_id.toString() === i.dataParsed.id.toString()) {

              if (i.dataParsed.title !== this.title) {
                i.diffChips.push({name: 'Title', field_name: 'title', current: this.title, new: i.dataParsed.title})
              }

              if (i.dataParsed.tags.length !== tags.length) {
                i.diffChips.push({
                  name: 'Tags',
                  field_name: 'tags',
                  current: tags.join(', '),
                  new: i.dataParsed.tags.join(', '),
                  newRaw: i.dataParsed.tags.join(',')
                })
              } else {
                if (!(i.dataParsed.tags.map(t => tags.includes(t)).every(e => e === true))) {
                  i.diffChips.push({
                    name: 'Tags',
                    field_name: 'tags',
                    current: tags.join(', '),
                    new: i.dataParsed.tags.join(', '),
                    newRaw: i.dataParsed.tags.join(',')
                  })
                }
              }

            }

            // console.log('i.dataParsed', this.mgid_id.toString(), this.mgid_id_2.toString(), i.dataParsed.id)
            // console.log('tags_2', tags_2)

            if (this.abtest_on === 1 && this.mgid_id_2.toString() === i.dataParsed.id.toString()) {

              if (i.dataParsed.title !== this.title_2) {
                i.diffChips.push({
                  name: 'Title 2',
                  field_name: 'title_2',
                  current: this.title_2,
                  new: i.dataParsed.title
                })
              }

              if (i.dataParsed.tags.length !== tags_2.length) {
                i.diffChips.push({
                  name: 'Tags 2',
                  field_name: 'tags_2',
                  current: tags_2.join(', '),
                  new: i.dataParsed.tags.join(', ')
                })
              } else {
                if (!(i.dataParsed.tags.map(t => tags_2.includes(t)).every(e => e === true))) {
                  i.diffChips.push({
                    name: 'Tags 2',
                    field_name: 'tags_2',
                    current: tags_2.join(', '),
                    new: i.dataParsed.tags.join(', ')
                  })
                }
              }
            }

            return i
          }))
          // console.info('teaserEternalUpdates', this.teaserEternalUpdates)
          this.loading = false
        } catch (e) {
          console.error('getTeaserEternalUpdates', e)
          this.loading = false
        }
      }
    },

    async refreshTasks() {
      if (this.tabs === 'tab-tasks') {
        await this.getTeaserTasks()
      }
      if (this.tabs === 'tab-ab-tasks') {
        await this.getTeaserTasksAbTasks()
      }
    },

    remove(item) {
      this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
      this.selectedTags = [...this.selectedTags]

      const i = this.imgTagsWithRating.findIndex((t) => t.tag === item)
      if (i !== -1) {
        this.imgTagsWithRating[i].selected = false
      }

    },

    selectTagInCloud(item) {
      if (this.selectedTags.indexOf(item.tag) === -1) {
        this.selectedTags.push(item.tag)
        item.selected = true
      }
    },

    clearSelectedTags() {
      this.imgTagsWithRating = this.imgTagsWithRating.map((t) => {
        t.selected = false
        return t
      })
    },

    async getImagesList() {
      try {
        const params = {type: null, limit: this.imagesPerPage, page: this.pageImages - 1}

        // debugger

        // console.log('getImagesList', params)

        if (this.chooseImageDialogFirstRun) {
          if (this.chooseImageDialogForImg1 && this.thisImage !== '') {
            params.url = this.thisImage
          } else if (!this.chooseImageDialogForImg1 && this.thisImage_2 !== '') {
            params.url = this.thisImage_2
          }
          this.chooseImageDialogFirstRun = false

          if (params.url) {
            if (params.url.match(/video/)) {
              this.imageType = 'video'
              params.type = 'video'
            } else {
              params.type = this.imageType
            }
          } else {
            params.type = this.imageType
          }

        } else {
          params.type = this.imageType
        }

        if (this.$route.params.section === 'Geniee') {
          params.type = 'banner'
        }

        if (this.uploadNewImage) {
          params.url = this.selectedImage.url
          this.uploadNewImage = false
          console.log('params', params)
        }

        if (this.selectedTags.length > 0) {
          params.tags = this.selectedTags.join(',')
        }

        const res = await api.dspTeaserImages(params)
        // console.log('dspTeaserImages RES', res)
        if (!res.data.error) {
          if (params.url) {
            this.pageImages = res.data.page + 1
          }

          if (this.imageType === 'video') {

            this.images = res.data.images.map(img => {

              if (img.url.match(/^https:\/\/img\.rtbsystem\.org\/video/)) {
                img.videoOptions = {
                  fluid: true,
                  autoplay: true,
                  controls: false,
                  sources: [
                    {
                      src: img.url,
                      type: 'video/mp4'
                    }
                  ],
                  loop: true
                }
              }

              if (img.url.match(/^\/video\//)) {
                img.videoOptions = {
                  fluid: true,
                  autoplay: true,
                  controls: false,
                  sources: [
                    {
                      src: this.getImgHost + img.url + `/2x1`,
                      type: 'video/mp4'
                    }
                  ],
                  loop: true
                }
              }


              return img
            })

          } else {
            this.images = res.data.images
          }

          this.pageImagesTotal = Math.floor(res.data.total / this.imagesPerPage) + 1

          if (res.data.total < this.pageImagesTotal) {
            this.pageImages = 1
          }
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('openChooseImageDialog', e)
      }
    },

    async getImagesTags() {
      try {
        let type
        if (this.$route.params.section === 'Geniee') {
          type = 'banner'
        } else {
          type = this.imageType
        }

        const res = await api.dspTeaserImagesTags(type)
        // console.log('dspTeaserImages RES', res)
        if (!res.data.error) {
          this.$set(this, 'imgTags', res.data.tags)
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('openChooseImageDialog', e)
      }
    },

    async getImagesTagsWithRating() {
      try {
        let type
        if (this.$route.params.section === 'Geniee') {
          type = 'banner'
        } else {
          type = this.imageType
        }
        const res = await api.dspTeaserImagesTagsWithRating(type)
        // console.log('getImagesTagsWithRating RES', res)
        if (!res.data.error) {
          this.$set(this, 'imgTagsWithRating', res.data.tags.map(t => {
            return {...t, selected: false}
          }))
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('getImagesTagsWithRating', e)
      }
    },

    async openChooseImageDialog(isFirsImage) {
      if (this.isMgidModerate) {
        return
      }
      this.chooseImageDialogForImg1 = isFirsImage
      this.chooseImageDialog = true
      this.getImagesTags()
      this.getImagesList()
    },

    switchImageType() {
      console.log('DialogAddNewVideo', this.imageType)
      this.getImagesTagsWithRating()
      this.getImagesTags()
      this.getImagesList()
    },

    copyToClipboard(text) {
      this.$copyText(text.toString()).then((e) => {
        this.snackbarText = text
        this.snackbar = true
        console.log('Copy OK', e)
      }, (e) => {
        console.log('Copy Error', e)
      })

    },

    imageStyle(img) {
      if (this.selectedImage.id === img.id) {
        return {border: 'thick solid rgb(76, 175, 80)', 'border-width': '10px'}
      }
      if (this.chooseImageDialogForImg1 && img.url === this.thisImage ||
          !this.chooseImageDialogForImg1 && img.url === this.thisImage_2) {
        return {border: 'thick dotted rgb(76, 175, 80)', 'border-width': '10px'}
      }
      return ''
    },

    onClickImage(img) {
      console.log('onClickImage', img)
      if (this.isMgidModerate) {
        return
      }
      if (img.id === this.selectedImage.id) {
        this.$set(this, 'selectedImage', {})
      } else {
        this.selectedImage = img
      }

    },
    onSelectImage() {
      if (this.chooseImageDialogForImg1) {
        this.thisImage = this.selectedImage.url

        if (this.thisImage.match(/video/)) {
          this.thisVideoOptions = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.thisImage,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        }
      } else {
        this.thisImage_2 = this.selectedImage.url
        if (this.thisImage_2.match(/video/)) {
          this.thisVideoOptions = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.thisImage_2,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        }
      }
      this.chooseImageDialog = false
    },

    openImageTagEditor(img) {
      this.selectedImage = img

      console.log(img)

      this.tag1 = img.tag1
      this.tag2 = img.tag2
      this.tag3 = img.tag3

      // getImageUrl

      if (img.url.match(/video/)) {

        if (img.url.match(/^https:/)) {
          this.selectedImage.videoOptions = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: img.url,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        } else {
          this.selectedImage.videoOptions_1x1 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img.url + `/1x1`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
          this.selectedImage.videoOptions_2x1 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img.url + `/2x1`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
          this.selectedImage.videoOptions_3x2 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img.url + `/3x2`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
          this.selectedImage.videoOptions_4x3 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img.url + `/4x3`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
          this.selectedImage.videoOptions_16x9 = Object.assign({}, {
            fluid: true,
            autoplay: true,
            controls: false,
            sources: [
              {
                src: this.getImgHost + img.url + `/16x9`,
                type: 'video/mp4'
              }
            ],
            loop: true
          })
        }
        this.dialogEditVideoTags = true
      } else {
        this.dialogEditImageTags = true
      }
    },

    async onNewImage(img) {
      console.log('onNewImage', img)
      this.selectedImage = img
      this.uploadNewImage = true
      await this.getImagesTags()
      await this.getImagesList()

    },

    onCancelImageDialog() {
      this.chooseImageDialog = false
      if (this.openImageDialogEditor !== '') {
        this.dialog = false
      }
    },

    async onClickRowTeaserEternalUpdates(v) {
      // console.log('onClickRowTeaserEternalUpdates', v)
      this.selectedExternalUpdateItem = v
      this.isVisibleCreateTaskFromExternalUpdates = true

      // dspTeaserExternalUpdatesListTeasersForTasks
      this.selectedCreateTaskForExternalUpdateDstLoading = true
      try {
        const res = await api.dspTeaserExternalUpdatesListTeasersForTasks({
          networkName: this.$route.params.section,
          campaignId: this.id,
          teaserId: this.selectedExternalUpdateItem.teaser_id
        })
        this.selectedCreateTaskForExternalUpdateDstLoading = false
        if (!res.data.error) {
          // console.info('dspTeaserExternalUpdatesListTeasersForTasks', res)
          this.selectedCreateTaskForExternalUpdateDst = res.data.map(i => {
            i.createtask = false
            i.createtask_2 = false
            return i
          })
          // console.info('this.selectedCreateTaskForExternalUpdateDst', this.selectedCreateTaskForExternalUpdateDst)
          // this.$emit('saved')
          // this.dialog = false
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        this.selectedCreateTaskForExternalUpdateDstLoading = false
        console.error('onClickRowTeaserEternalUpdates', e)
      }

    },

    onClickViewExternalUpdateItem(v) {
      // console.log('onClickViewExternalUpdateItem', v)
      this.selectedExternalUpdateItem = v
      this.selectedExternalUpdateItemDataPretty = JSON.stringify(JSON.parse(v.data), null, 2)
      this.isVisibleViewExternalUpdateItem = true
    },

    async onCreateTaskFromExternalUpdates() {

      let teasers = this.selectedCreateTaskForExternalUpdateDst.map(i => {
        if (this.selectedCreateTaskForExternalUpdateDstSelected.some(selected => i.id === selected.id)) {
          return false
        }

        if (i.createtask || i.abtest_on === 1 && i.createtask_2) {
          const r = {teaserId: i.id}

          if (i.createtask) {
            r.mgid_id = i.mgid_id
          }

          if (i.abtest_on === 1 && i.createtask_2) {
            r.mgid_id_2 = i.mgid_id_2
          }

          return r
        } else {
          return false
        }
      }).filter(v => v !== false)

      // console.log('selectedCreateTaskForExternalUpdateDst', teasers)

      teasers = teasers.concat(this.selectedCreateTaskForExternalUpdateDstSelected.map((i) => {
        const r = {teaserId: i.id, mgid_id: i.mgid_id.toString()}
        if (i.abtest_on === 1) {
          r.mgid_id_2 = i.mgid_id_2.toString()
        }
        return r
      }))

      if (teasers.length > 0 && this.selectedCreateTaskForExternalUpdate.length > 0) {
        const dsp_task = {}
        const abtest_task = {}

        const ad_type = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'ad_type')

        if (ad_type) {
          dsp_task.ad_type = ad_type.new
        }

        const landing_type = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'landing_type')

        if (landing_type) {
          dsp_task.landing_type = landing_type.new
        }

        const title = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'title')

        if (title) {
          abtest_task.title = title.new
        }

        const title_2 = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'title_2')

        if (title_2 && title === undefined) {
          abtest_task.title = title_2.new
        }

        const tags = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'tags')

        if (tags) {
          abtest_task.tags = tags.newRaw
        }

        const tags_2 = this.selectedCreateTaskForExternalUpdate.find(t => t.field_name === 'tags_2')

        if (tags_2 && tags === undefined) {
          abtest_task.tags = tags_2.newRaw
        }

        const request = {teasers}

        if (Object.keys(dsp_task).length > 0) {
          request.dsp_task = dsp_task
        }

        if (Object.keys(abtest_task).length > 0) {
          request.abtest_task = abtest_task
        }

        // console.log('res', teasers, this.selectedCreateTaskForExternalUpdate)

        // console.log('res request', request)

        try {
          const res = await api.dspTeaserUpdateFromCallback(request)

          if (res.data.error) {
            console.error('dspTeaserUpdateFromCallback error', res.data)
          } else {
            this.$set(this, 'resultOfCreatedDspTaskForCallback', res.data.resultOfCreateDspTasks)
            this.$set(this, 'resultOfCreatedAbTaskForCallback', res.data.resultOfCreateAbTasks)
            this.$emit('do-task')
            this.isVisibleResultOfCreatedTaskForCallback = true
          }
        } catch (e) {
          console.error('dspTeaserUpdateFromCallback', e)
        }

      }

      this.$nextTick(() => {
        this.isVisibleCreateTaskFromExternalUpdates = false
      })
    },
    onChangeThisLang() {
      if (this.onModeration && this.lang === '') {
        return
      }
      if (this.ro && this.lang === '') {
        this.allowSaveThisLang = true
      }
    },
    enableThisLangSaveIcon() {
      if (this.allowSaveThisLang && !this.savedThisLang) {
        return 'mdi-content-save'
      } else {
        return ''
      }
    },

    async saveLang() {
      console.log('saveLang')
      try {
        const res = await api.dspUpdateLang(
            {networkName: this.$route.params.section, id: this.id, lang: this.thisLang})
        if (!res.data.error) {
          // console.info('dspTeaserNew', res)
          this.$emit('do-task')
          this.allowSaveThisLang = false
          this.savedThisLang = true
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('saveLang', e)
      }
    },

    validateLang(value) {
      if (value !== null && value !== '') {
        return true
      }
      return 'Set lang'
    },
    isRoLang() {
      // ro && lang !== '' || savedThisLang
      if (this.onModeration) {
        return this.ro
      } else {
        return this.ro && this.lang !== '' || this.savedThisLang
      }

    },
    isRoAdType() {
      if (this.$route.params.section === 'Mgid') {
        return this.ro
      }
      return this.ro && !this.viewTeaser
    },
    onClickUserAgentInput() {
      if (this.os_filter.length === 0 && this.thisUserAgent.length === 0) {
        this.thisUserAgent = this.userAgentList.map(ua => ua)
      }
    },
    onClickUserAgentBlur() {
      if (this.userAgentList.length === this.thisUserAgent.length) {
        this.thisUserAgent = []
      }
    },

    getPeerclickCampaignLink(item) {
      let url
      try {
        url = new URL(item)
      } catch (e) {
        // console.log(e)
        return ''
      }
      const match = url.pathname.match(/^\/(......)/)
      if (match && match.length >= 2) {
        let postfix = match[1]
        if (this.metaData && this.metaData.peerclick && this.metaData.peerclick[postfix]) {

          // console.log(postfix, this.metaData.peerclick[postfix])

          const dateStr = DateTime.fromObject({zone: 'America/Los_Angeles'})

          const filter = {
            "like": `[flow]id:${this.metaData.peerclick[postfix].campaign.id}`,
            "grouped[]": 1,
            "affiliate_c": null,
            "source_c": null,
            "selectedGroup": 0,
            "group_c": null,
            "datebegin": dateStr.toISODate(),
            "dateend": dateStr.toISODate(),
            "date_compare_begin": null,
            "date_compare_end": null,
            "id_custom_filter": null,
            "startHour": 0,
            "endHour": 23,
            "utc": "-07:00",
            "selectedUsers[]": [
              "my"
            ],
            "isTab": false,
            "offset": 0,
            "limit": 100,
            "sort": "[index]DESC",
            "subuser_c": null
          }

          const url = 'https://panel.peerclick.com/login/index.html#/stats?group=flow&tab=false&tab_filter=false&filter=' + encode(JSON.stringify(filter))
          return url
        }
        return ''
      }
    },

    getPeerclickOffersLink(item) {
      let url
      try {
        url = new URL(item)
      } catch (e) {
        // console.log(e)
        return []
      }
      const match = url.pathname.match(/^\/(......)/)
      if (match && match.length >= 2) {
        let postfix = match[1]
        if (this.metaData && this.metaData.peerclick && this.metaData.peerclick[postfix]) {

          // console.log(postfix, this.metaData.peerclick[postfix])

          const dateStr = DateTime.fromObject({zone: 'America/Los_Angeles'})

          return this.metaData.peerclick[postfix].offers.map(o => {

            const filter = {
              "like": `[landing]id:${o.id}`,
              "grouped[]": 3,
              "affiliate_c": null,
              "source_c": null,
              "selectedGroup": 0,
              "group_c": null,
              "datebegin": dateStr.toISODate(),
              "dateend": dateStr.toISODate(),
              "date_compare_begin": null,
              "date_compare_end": null,
              "id_custom_filter": null,
              "startHour": 0,
              "endHour": 23,
              "utc": "-07:00",
              "selectedUsers[]": [
                "my"
              ],
              "isTab": false,
              "offset": 0,
              "limit": 100,
              "sort": "[index]DESC",
              "subuser_c": null
            }

            const url = 'https://panel.peerclick.com/#/stats?filter=' + encode(JSON.stringify(filter))
            return url
          })
        }

      }
      return []
    },

    // getPeerclickOfferLink(item) {
    //
    // },

    // isEnableRoiForUsers() {
    //   // (ro && !viewTeaser) || !(isEnableRoiForUsers) || (createNew && $route.params.section === 'Mgid')
    //   console.log('FULL 1', this.isEnableRoiForSources, this.ro, this.viewTeaser, [0, 3, 20, 25, 31, 32, 35].includes(this.mode), this.createNew, this.$route.params.section === 'Mgid', this.mode)
    //   // console.log('FULL 2', (this.ro && !this.viewTeaser) || !(this.isEnableRoiForUsers) || (this.createNew && this.$route.params.section === 'Mgid'))
    //   // console.log('FULL 2', [0, 3, 20, 25, 31, 32, 35].includes(this.mode))
    //   return [0, 3, 20, 25, 31, 32, 35].includes(this.mode)
    //
    // }


    async teaserSetFolderActive() {
      this.loadingSetFolder = true
      try {
        const res = await this.teaserSetFolder('active')

        if (!res.data.error) {
          this.thisFolder = 'active'
          this.refreshDspTeasersTable = true
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('teaserSetFolder', e)
      }
      this.loadingSetFolder = false
    },
    async teaserSetFolderArchive() {
      this.loadingSetFolder = true
      try {
        const res = await this.teaserSetFolder('archive')
        if (!res.data.error) {
          this.thisFolder = 'archive'
          this.refreshDspTeasersTable = true
          console.log('teaserSetFolder archived', res)
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('teaserSetFolder', e)
      }
      this.loadingSetFolder = false
    },
    async teaserSetFolderDeleted() {
      this.loadingSetFolder = true
      try {
        const res = await this.teaserSetFolder('deleted')
        if (!res.data.error) {
          this.thisFolder = 'deleted'

          this.refreshDspTeasersTable = true

          this.dialog = false

          console.log('teaserSetFolder deleted', res)
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.error('teaserSetFolder', e)
      }
      this.confirmDeleteTeaser = false
      this.loadingSetFolder = false
    },

    async teaserSetFolder(name) {
      return await api.dspTeasersSetFolder({
        networkName: this.$route.params.section,
        id: this.id,
        name,
      })
    },
    delimit(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ]+/)]
      this.thisTags = [...new Set(v.reduce(reducer, []))]
    },
    delimit_2(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ]+/)]
      this.thisTags_2 = [...new Set(v.reduce(reducer, []))]
    }
  },
  watch: {
    dialog(value) {
      // console.log('dialog value', value)
      if (!value) {
        this.$emit('input', false)
      }
    },
    value(value) {
      this.dialog = value
    },

    thisTeam() {
      if (!this.init) {
        this.thisOwner = ''
        this.thisOwner_2 = ''

      } else {
        this.init = false
      }
      // if (v) {
      //   if (this.teams.value.length === 0) {
      //     // this.$set(this, 'thisTeam_2', {value: []})
      //   } else if (this.teams.value.length === 1) {
      //     // this.$set(this, 'thisTeam_2', this.thisTeam)
      //   }
      // } else {
      //   // this.$set(this, 'thisTeam_2', {value: []})
      // }
    },

    tabs(val) {
      if (val === 'tab-teaser') {
        if ((!this.viewTeaser && !this.ro) || this.onModeration) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.form.validate()
            })
          }, 1000)
        }
      }
      if (val === 'tab-tasks') {
        this.getTeaserTasks()
      }
      if (val === 'tab-ab-tasks') {
        this.getTeaserTasksAbTasks()
      }

      if (val === 'tab-eternal-updates') {
        this.getTeaserEternalUpdates()
      }

      if (val === 'tab-auction-stat') {
        this.getAuctionStat()
      }

      if (val === 'tab-cLimit-achieved') {
        this.getClimitAchievedItems()
      }

      // if (val === 'tab-target') {
      //   this.getTargetUa()
      // }
    },

    page() {
      if (this.tabs === 'tab-tasks') {
        this.getTeaserTasks()
      }
      if (this.tabs === 'tab-ab-tasks') {
        this.getTeaserTasksAbTasks()
      }

      if (this.tabs === 'tab-eternal-updates') {
        this.getTeaserEternalUpdates()
      }

      if (this.tabs === 'tab-auction-stat') {
        this.getAuctionStat()
      }

      if (this.tabs === 'tab-cLimit-achieved') {
        this.getClimitAchievedItems()
      }
    },
    itemsPerPage(value) {
      if (this.tabs === 'tab-tasks') {
        this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
        this.getTeaserTasks()
      }
      if (this.tabs === 'tab-ab-tasks') {
        this.getTeaserTasksAbTasks()
      }

      if (this.tabs === 'tab-eternal-updates') {
        this.getTeaserEternalUpdates()
      }

      if (this.tabs === 'tab-auction-stat') {
        this.getAuctionStat()
      }

      if (this.tabs === 'tab-cLimit-achieved') {
        this.getClimitAchievedItems()
      }
    },

    chooseImageDialog(val) {
      if (val) {
        this.getImagesTagsWithRating()
      } else {
        this.chooseImageDialogFirstRun = true
      }

    },
    pageImages() {
      this.getImagesList()
    },

    imagesPerPage() {
      this.getImagesList()
    },

    selectedTags() {
      this.getImagesList()
    },

    thisIsDisabledTags() {
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'form')) {
        this.$refs.form.validate()
      }
    },

    thisIsDisabledTags_2() {
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'form')) {
        this.$refs.form.validate()
      }
    },

    isVisibleCreateTaskFromExternalUpdates(v) {
      if (v === false) {
        this.selectedExternalUpdateItem = null
      }
    },

    thisOffer() {
      this.$refs.form.validate()
    },

    thisCountry(value) {
      if (this.createNew) {
        if (['us'].includes(value)) {
          this.thisMode = 31
          // this.thisRoiForSources = 100
          // this.thisRoiForUsers = 100
        } else if (['vn', 'pl', 'ua', 'id', 'it'].includes(value)) {
          this.thisMode = 31
          // this.thisRoiForSources = 100
          // this.thisRoiForUsers = 100
        } else {
          this.thisMode = 25
          // this.thisRoiForSources = 0
          // this.thisRoiForUsers = 0
        }

      }
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'form')) {
        this.$refs.form.validate()
      }
    },


    thisMode(value) {
      if (this.createNew) {
        // debugger
        if (value === 31) {
          if (['us'].includes(this.thisCountry)) {
            this.thisRoiForSources = 50
            this.thisRoiForUsers = 50
          } else if (['it'].includes(this.thisCountry)) {
            this.thisRoiForSources = 40
            this.thisRoiForUsers = 60
          } else if (['vn', 'pl', 'ua', 'id'].includes(this.thisCountry)) {
            this.thisRoiForSources = 100
            this.thisRoiForUsers = 100
          }
          // else {
          // this.thisMode = 25
          // this.thisRoiForSources = 0
          // this.thisRoiForUsers = 0
          // }
        } else {
          if (!(value === 32)) {
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 0
          }
        }
      } else {
        // mode 0 = roi_for_user 0
        // mode 3 = roi_for_user 0
        // mode 20 = roi_for_user 0
        // mode 25 = roi_for_user 25
        // mode 35 = roi_for_user 35

        switch (value) {
          case 0:
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 0
            break
          case 3:
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 0
            break
          case 20:
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 0
            break
          case 25:
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 25
            break
          case 35:
            this.thisRoiForSources = 0
            this.thisRoiForUsers = 35
            break
          default:
            if (!(value === 30 || value === 31 || value === 32)) {
              this.thisRoiForSources = 0
              this.thisRoiForUsers = 0
            }
        }
      }

      if (!([30, 31, 32, 33].includes(value))) {
        this.thisManual_opti = true
      }

      if (this.$refs.form) {
        this.$refs.form.validate()
      }
    },

    dspDep(v) {
      if (v) {
        this.teamsLocal = v
        this.initDialog()
        this.dialog = true
      }
    },

    isVisibleSelectDepDialog(v) {
      if (v === false && this.dspDep === null) {
        this.$emit('input', false)
      }
    },

    siteId() {
      this.getAuctionStat()
    },

    ro(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.$refs.form.validate()
      }
    }

    // thisUserAgent (v) {
    //   console.log('thisUserAgent', v)
    // }

    // activeTargetUa (value) {
    //   if (Array.isArray(value)) {
    //     if (value.length === 1) {
    //       const i = this.targetUa.findIndex(t => t.id === value[0])
    //       this.targetUaGroup = this.makeTreeForTarget(this.targetUa[i].group, 'include')
    //       this.targetUaList = this.makeTreeForTarget(this.targetUa[i].group, 'exclude')
    //     } else {
    //       this.targetUaList = undefined
    //       this.targetUaGroup = undefined
    //     }
    //   } else {
    //     this.targetUaList = undefined
    //     this.targetUaGroup = undefined
    //   }
    // }

    // team2 () {
    //
    //
    // },
    //
    // thisTeam_2 (v) {
    //   if (v) {
    //     this.owners_2 = v.thisTeam
    //   } else {
    //     this.$set(this, 'owners_2', [])
    //   }
    // }
  }
}
</script>

<style>
.v-window__container {
  height: 100%
}

.on-hover {
  transition: opacity .4s ease-in-out;
  opacity: 0.7;
}
</style>